<template>
  <div>
    <h1 class="viewTitle">Uživatelský profil</h1>
    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        color="success"
    >
      Profil aktualizován

      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Zavřít
        </v-btn>
      </template>
    </v-snackbar>

    <v-form
        v-model="valid"
        ref="form"
        lazy-validation
    >
      <v-container>
        <v-row>
          <v-col
              cols="12"
              md="4"
          >
            <v-text-field
                v-model="getUser.nickname"
                :rules="nicknameRules"
                :counter="20"
                label="Přezdívka"
                required
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              md="4"
          >
            <v-text-field
                v-model="getUser.name"
                :rules="nameRules"
                :counter="20"
                label="Jméno"
            ></v-text-field>
          </v-col>

          <v-col
              cols="12"
              md="4"
          >
            <v-text-field
                v-model="getUser.surname"
                :rules="nameRules"
                :counter="20"
                label="Příjmení"
            ></v-text-field>

          </v-col>

          <v-col
              cols="12"
              md="4"
          >
            <v-text-field
                v-model="getUser.email"
                :rules="emailRules"
                label="E-mail"
                required
            ></v-text-field>
          </v-col>

          <v-col
              cols="12"
              md="4"
          >
            <v-text-field
                v-model="getUser.company"
                label="Název společnosti"
            ></v-text-field>
          </v-col>

          <v-col
              cols="12"
              md="4"
          >
            <v-text-field
                v-model="getUser.address"
                label="Adresa"
            ></v-text-field>
          </v-col>

          <v-col
              cols="12"
              md="4"
          >
            <v-text-field
                ref="ico"
                v-model="getUser.ICO"
                label="IČO"
            ></v-text-field>
          </v-col>

          <v-col
              cols="12"
              md="4"
          >
            <v-text-field
                v-model="getUser.DIC"
                label="DIČ"
            ></v-text-field>
          </v-col>

          <v-col
              cols="12"
              md="4"
          >
            <v-text-field
                v-model="getUser.bank_account"
                label="Bankovní účet"
            ></v-text-field>
          </v-col>

          <v-col
              cols="12"
              md="4"
          >
            <v-text-field
                v-model="getUser.hour_rate"
                :rules="[numberRule]"
                label="Hodinová sazba (v Kč)"
            ></v-text-field>
          </v-col>

          <v-col
              cols="12"
              md="4"
          >
            <v-select
                :items="platceDPH"
                :item-text="'name'"
                :item-value="'value'"
                v-model="getUser.platce_DPH"
                name="platceDPH"
                label="Jsem plátce DPH:"
            ></v-select>
          </v-col>

        </v-row>
        <v-card-actions class="justify-end" style="padding: 0 !important;">
          <v-btn
              :disabled="emptyInput"
              style="z-index: 0"
              color="primary"
              @click="updateUser"
          >
            Aktualizovat
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-form>

    <v-container>
      <v-row>
        <v-col
            cols="12"
            md="4"
        >
          <v-btn
              style="z-index: 0"
              color="primary"
              outlined
              to="/invoices-history"
          >
            Historie vystavených faktur
          </v-btn>
        </v-col>
        <v-col
            cols="12"
            md="4"
        >
          <FakturoidConnect ref="FakturoidConnect"></FakturoidConnect>
        </v-col>
      </v-row>
    </v-container>



  </div>

</template>

<script>
import gql from "graphql-tag";
import FakturoidConnect from "@/components/FakturoidConnect";

export default {
  name: "UserProfile",
  components: {FakturoidConnect},
  data: () => ({
    valid: false,
    snackbar: false,
    timeout: 2000,
    platceDPH: [
      { name: 'Ne', value: 0 },
      { name: 'Ano', value: 1 }
    ],
    selectedPlatceDPH: 0,
    numberRule: v  => {
      if (!v.trim()) return true;
      if (!isNaN(parseFloat(v)) && v >= 0 && v <= 99999) return true;
      return 'Hodnota musí být kladná!';
    },
    nicknameRules: [
        v => !!v || 'Jméno je požadováno!',
        v => v.length <= 40 || 'Jméno musí být menší než 40 znaků!',
    ],
    nameRules: [
      v => v.length <= 40 || 'Jméno musí být menší než 40 znaků!',
    ],

    emailRules: [
      v => !!v || 'E-mail je požadován!',
      v => /.+@.+/.test(v) || 'E-mail musí být validní!',
    ],
  }),

  watch: {
    "$store.state.profile": {
      handler: function() {
        this.$store.dispatch('getUser');
      },
      immediate: true // provides initial (not changed yet) state
    },
  },


    computed: {
    getUser() {
      return this.$store.state.user
    },

      emptyInput() {
        const emailRegex = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;

        return !emailRegex.test(this.getUser.email) || this.getUser.nickname <= 0;
      }

  },

  created () {
    this.$store.dispatch('getUser');

    if (!this.getUser) {
      this.snackbar = true;
    } else {
      this.snackbar = false;
    }

    //this.$refs.FakturoidConnect.fakturoidApiKey = this.getUser.fakturoid_api_key;
  },
  methods: {
    updateUser() {
      let hourRate = 0;
      if(this.getUser.hour_rate) {
          hourRate = this.getUser.hour_rate;
      }

      let vatPayer = 0;
      if(this.getUser.platce_DPH) {
          vatPayer = this.getUser.platce_DPH;
      }

      let name = this.getUser.name ? this.getUser.name : "";
      let surname = this.getUser.surname ? this.getUser.surname : "";
      let company = this.getUser.company ? this.getUser.company : "";
      let address = this.getUser.address ? this.getUser.address : "";
      let ICO = this.getUser.ICO ? this.getUser.ICO : "";
      let DIC = this.getUser.DIC ? this.getUser.DIC : "";
      let bank_account = this.getUser.bank_account ? this.getUser.bank_account : "";


        this.$apollo.mutate({
        mutation: gql(`mutation {
          update_user (
            name: "` + this.getUser.nickname  + `",
            real_name: "` + name + `",
            real_surname: "` + surname + `",
            email: "` + this.getUser.email + `",
            company: "` + company + `",
            address: "` + address + `",
            ICO: "` + ICO + `",
            DIC: "` + DIC + `",
            bank_account: "` + bank_account + `",
            hour_rate: ` + hourRate + `,
            platce_DPH: ` + vatPayer + `
          ) {
            id
          }
          }`
        )
      }).then(() => {
          this.$store.commit("PROF");
          this.snackbar = true;
      });
    }
  },

};
</script>

<style scoped></style>
