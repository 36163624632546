<template>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            class="addBtn"
            height="40"
            width="40"
            depressed
            min-width="40"
            color="#3D70B2"
            v-bind="attrs"
            v-on="on"
        >
          +
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Přidat nový úkol</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
            <v-row>
              <v-col
                  cols="12"
              >
                <v-text-field
                    label="Název úkolu*"
                    v-model="taskName"
                    required
                    :rules="rules"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    label="Datum splnění"
                    type="date"
                    hint="Do kdy má být úkol hotový ?"
                    persistent-hint
                    required
                    v-model="dueDate"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    label="Časová náročnost"
                    hint="Později slouží pro fakturaci klientovi"
                    persistent-hint
                    type="number"
                    v-model="timeEstimate"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-select
                    v-model="participans"
                    :items="members.data"
                    item-text="name"
                    item-value="id"
                    label="Úkol pro:"
                ></v-select>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-select
                        v-model="project"
                        :items="projects.data"
                        item-text="name"
                        item-value="id"
                        label="Projekt*"
                        :rules="[(v) => !!v || 'Vyberte prosím projekt']"
                ></v-select>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-autocomplete
                    :items="['Low', 'Normal', 'High']"
                    label="Priorita"
                    v-model="priority"
                ></v-autocomplete>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-select
                    :items="['Backlog', 'In Progress', 'Done']"
                    label="Status"
                    required
                    v-model="status"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-textarea
                        label="Popis úkolu"
                        v-model="description"
                        multi-line
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <input type="file" multiple @change="uploadFile">
                <!--<v-file-input
                    counter
                    multiple
                    show-size
                    truncate-length="15"
                    @change="uploadFile"
                ></v-file-input>-->
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <div v-for="att in attachments" :key="att.name">
                  <span style="color: #000000">

                    <v-icon v-if="att.type.indexOf('image') !== -1" size="22" left>mdi-file-image-outline</v-icon>
                    <v-icon v-else-if="att.type.indexOf('pdf') !== -1" size="22" left>mdi-file-pdf-outline</v-icon>
                    <v-icon v-else-if="att.type.indexOf('excel') !== -1 || att.type.indexOf('sheet') !== -1" size="22" left>mdi-microsoft-excel</v-icon>
                    <v-icon v-else-if="att.type.indexOf('office') !== -1 || att.type.indexOf('document') !== -1" size="22" left>mdi-microsoft-word</v-icon>
                    <v-icon v-else-if="att.type.indexOf('compressed') !== -1" size="22" left>mdi-folder-zip</v-icon>
                    {{ att.name }}
                  </span>
                  <v-btn
                      style="margin-bottom: 0"
                      @click="removeFile(att.name)"
                      icon
                      class="deleteFile"
                  >
                    <v-icon size="22" color="black">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </div>
              </v-col>

            </v-row>
              </v-form>
          </v-container>
          <small>*požadováno</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              @click="addTask()"
          >
            Uložit
          </v-btn>
          <v-btn
              color="primary"
              outlined
              @click="dialog = false"
          >
            Zavřít
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import axios from "axios";


export default {
  name: "AddTask",
  components: {},
  data: () => ({
    dialog: false,
    valid: true,
    rules: [
      value => !!value || 'Požadováno',
      value => (value && value.length >= 1) || 'Minimálně 1 znak',
    ],
    taskName: '',
    dueDate: '',
    status: '',
    timeEstimate: '',
    participans: '',
    project: 0,
    priority: '',
    description: '',
    members: [],
    projects: [],
    attachments: [],
  }),

  methods: {
    addTask() {
      if (this.$refs.form.validate()) {

        this.$emit('addTaskChild', this.taskName, this.description, this.timeEstimate, this.participans, this.dueDate, this.project, this.priority, this.status, this.attachments)
        this.dialog = false
      } else {
        return false
      }
    },

    loadSelectorMenuItems() {
      // Users
      axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
        'users{' +
          'data{' +
            'id,' +
            'name,' +
          '}' +
        '}' +
        '}'
      ).then(response => {
        this.members = response.data.data.users;
        console.log(this.members);
      });

      // Projects
      axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
        'projects(active: true){' +
          'data{' +
            'id,' +
            'name,' +
          '}' +
        '}' +
        '}'
      ).then(response => {
        this.projects = response.data.data.projects;
        console.log(this.projects);
      });
    },

    uploadFile(event) {
        for (let i = 0; i < event.target.files.length; i++) {
            this.attachments.push(event.target.files[i]);
        }
        console.log(this.attachments);
    },

    removeFile(name) {
      let index = this.attachments.findIndex(x => x.name === name);
      if(index >= 0) {
          this.attachments.splice(index, 1);
      }
    }

  },

  created() {
      this.loadSelectorMenuItems();
  }
}
</script>

<style scoped>

  .deleteFile:hover i {
    color: red;
  }

  .addBtn {
    font-family: "AkkuratLL-Bold",sans-serif !important;
    border: 1px solid #3D70B2 ;
    border-radius: 10px;
    color: #ffffff;
    background-color: #3D70B2;
    font-size: 20px;
    font-weight: bold;
    display: inline-block;
    margin-top: 1%;
    margin-bottom: 1%;
    z-index: 0;
  }

</style>
