<template>
  <div>
    <div class="titleAndBtn">
      <h1 class="viewTitle">Reporty pro firmu</h1>
      <v-btn
          color="primary"
          outlined
          class="ma-2"
          :to="{ name: 'Reports'}"
          style="z-index: 0"
      >
        Zpět
      </v-btn>
    </div>

    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
    >
      You have to log in first!

      <template v-slot:action="{ attrs }">
        <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <div class="filterCont">
      <v-row>
        <v-col
            cols="12"
            sm="6"
            md="2"
        >
          <div class="text-center">
            <v-select
                v-model="project"
                :items="projects.data"
                item-text="name"
                item-value="id"
                label="Project"
            ></v-select>
          </div>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            md="2"
        >
          <div class="text-center">
            <v-select
                v-model="client"
                :items="clients.data"
                item-text="contactInfo.company"
                item-value="id"
                label="Client"
            ></v-select>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-btn
            style="z-index: 0; margin-left: 12px"
            color="primary"
            @click="filterRecords(project, client)"
        >
          FILTROVAT
        </v-btn>
        <v-btn
            style="z-index: 0; margin-left: 12px"
            color="primary"
            outlined
            @click="removeFiltres"
        >
          ZRUŠIT FILTR
        </v-btn>
      </v-row>
    </div>

    <h4
        style="margin-bottom: 20px; margin-top: 20px"
    >
      <b>Celkový čas:</b>&nbsp;{{ totalTime }}
    </h4>

    <v-simple-table
        fixed-header
    >
      <template v-slot:default>
        <thead v-if="reports">
        <tr>
          <th>
            <v-checkbox v-model="selectedAll" />
          </th>
          <th class="text-left">Předpokládaný čas</th>
          <th class="text-left">Jméno</th>
          <th class="text-left">Název úkolu</th>
          <th class="text-left">Název projektu</th>
          <th class="text-left">Společnost</th>
        </tr>
        </thead>
        <tbody v-if="reports">
        <tr v-for="report in reports.data" :key="report.id">
          <td>
            <v-checkbox
                v-model="selectedTasksId"
                :value="report.id"
            ></v-checkbox>
          </td>
          <td>{{ report.time_estimate }}</td>
          <td>{{ report.name }}</td>
          <td v-if="report.project != null">{{ report.project.name }}</td>
          <td v-if="report.project.client != null">{{ report.project.client.contactInfo.company }}</td>
          <td v-else> No company</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>




    <v-btn
        color="primary"
        class="ma-2"
        :disabled="isSelected"
        :to="{ name: 'Invoices',
                query: {
                  tasksId: selectedTasksId
                }
        }"
    >
      Vytvořit fakturu
    </v-btn>


  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ReportsCompany",
  components: {},
  data: () => ({
    reports: [],
    snackbar: false,
    timeout: 2000,
    member: '',
    client: '',
    project: '',
    members: [],
    projects: [],
    clients: [],
    invoices: [],
    selectedTasksId: [],
    selected: [],
    selectAll: false,
    count: 20,
    totalTime: 0
  }),
  methods: {

    removeFiltres() {
      this.client = ''
      this.project = ''

      this.filterRecords();
      console.log("FILTRES REMOVED")
    },

    refetch () {
      this.$apollo.queries.reports.refetch()
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },

    filterRecords(project, client) {
      let argumentString = '';
      if(project) { argumentString += 'project_id:' + project + ','; }
      if(client) { argumentString += 'client_id:' + client + ','; }

      if(argumentString) { argumentString = '(' + argumentString + ')'; }

      axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
        'reports_company' + argumentString + '{' +
          'data{' +
            'id,' +
            'name,' +
            'time_estimate,' +
            'project{' +
              'id,' +
              'name,' +
                'client{' +
                'id,' +
                'contactInfo{' +
                  'company,' +
                '}' +
              '}' +
            '}' +
          '}' +
        '}' +
        '}'
      ).then(response => {
        this.reports = response.data.data.reports_company;
        console.log(response);
      })
    },

    loadFilterItems() {
      // Users
      axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
          'users{' +
          'data{' +
          'id,' +
          'name,' +
          '}' +
          '}' +
          '}'
      ).then(response => {
        this.members = response.data.data.users;
      });

      // Projects
      axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
          'projects{' +
          'data{' +
          'id,' +
          'name,' +
          '}' +
          '}' +
          '}'
      ).then(response => {
        this.projects = response.data.data.projects;
      });

      // Clients
      axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
          'clients{' +
          'data{' +
          'id,' +
          'contactInfo{' +
          'company' +
          '}' +
          '}' +
          '}' +
          '}'
      ).then(response => {
        this.clients = response.data.data.clients;
      });

      /*let startDate = new Date();
      startDate.setMonth(startDate.getMonth()-1);
      this.startDate = startDate.toISOString().substr(0, 10);*/
    },

  },
  computed: {

    isSelected() {
      console.log("(ReportsComapny.vue) TASKS ID's: " + this.selectedTasksId )
      return this.selectedTasksId.length <= 0
    },

    dateRangeText () {
      return this.dates.join(' ~ ')
    },
    getUser() {
      return this.$store.state.user
    },
  },

  created () {

    this.$store.dispatch('getUser');

    this.filterRecords();
    this.loadFilterItems();
  },
}
</script>

<style scoped>

</style>
