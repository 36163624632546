<template>
  <div>
  <div v-if="dashboard_tasks">

    <div class="titleAndBtn">
      <h2 class="viewTitle">Naposledy řešené úkoly</h2>
      <AddTask @addTaskChild="onAddTask" />
    </div>


    <v-card v-for="task in dashboard_tasks.data" :key="task.id"
            style="z-index: 0"
            class="mx-auto dashBoradTask"
            max-width="100%"
            hover
            outlined
    >
      <v-card-text
        class="innerTask"
      >
        <v-btn
            style="min-width: auto; z-index: 0"
            class="checkButton"
            raised
            depressed
            icon
            @click="checkTaskDone(task.id)"
        >
          <v-icon size="20px">
            mdi-checkbox-marked-circle-outline
          </v-icon>
        </v-btn>
        <div
            class="taskLeft"
            @click="() => showTaskDetailModal($parent, task)"
        >
        <div class="projectAndStatus" v-if="task.project != null">
          <span style="display: inline-block;margin-right: 10px">{{ task.project.name }}</span>
          <div v-if="task.priority" style="display: inline-block">
            <div v-if="task.priority.name === 'Low'" class="priorityLow">Nízká</div>
            <div v-if="task.priority.name === 'Normal'" class="priorityMedium">Střední</div>
            <div v-if="task.priority.name === 'High'" class="priorityHigh">Vysoká</div>
          </div>
        </div>
        <div class="projectAndStatus" v-else style="font-size: 12px">
          Bez projektu
        </div>

        <p v-if="task.name != null" class="taskName" style="margin-bottom: 0">
          {{ task.name }}
        </p>
        <p v-else class="taskName" style="margin-bottom: 0; color: #FF5666">
          Bez názvu
        </p>
          <span v-if="task.status != null" class="taskStatus">{{ task.status.name }}</span>
        </div>
        <div class="rightTask">
          <div v-if="task.user != null" class="participians flexRight">
            <div class="subTitle">Řešitel</div>
            <v-avatar color="primary" size="24" style="font-size: 12px">
              {{ task.user.name.charAt(0) }}
            </v-avatar>
          </div>
          <div style="display: inline-block" class="flexRight">
            <div class="subTitle">Datum splnění</div>
            <div v-if="task.due_date != null" class="text--primary font-weight-bold date">
              {{ task.due_date | moment("DD. MM. YYYY") }}
            </div>
            <div v-else>
              -
            </div>
          </div>
          <v-btn
              style="min-width: auto; z-index: 0"
              class="flexRight"
              raised
              depressed
              icon
              @click="runTask(task.id)"
          >
            <v-icon size="40px">
              mdi-play-circle
            </v-icon>
          </v-btn>

        </div>
      </v-card-text>
    </v-card>
    </div>
    <div v-if="dashboard_tasks.data.length === 0">
      <span>Žádné úkoly</span>
    </div>

    <v-snackbar
        v-model="snackbar"
        color="success"
    >
      Úkol byl úspěšně vytvořen

      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Zavřít
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
        v-model="snackbarDone"
        color="success"
    >
      Úkol je hotový. Gratulujeme!

      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbarDone = false"
        >
          Zavřít
        </v-btn>
      </template>
    </v-snackbar>

  </div>

</template>

<script>
import gql from "graphql-tag";
import AddTask from "@/components/AddTask";
import axios from "axios";


export default {
  name: "DasboardTask",
  components: {AddTask},

  data() {
      return {
        snackbarDone: false,
        snackbar: false,
        dashboard_tasks: [],
        taskName: '',
        projectName: '',
        taskId: null,
        timeStart: 0,
      };
  },

  computed: {
    getUser() {
      return this.$store.state.user
    },
  },

  created () {
      this.$store.dispatch('getUser');
      this.getLastTasks();
  },

  watch: {
    "$store.state.tasks": {
      handler: function(nv) {
        console.log(nv);
        this.getLastTasks();
      },
      immediate: true // provides initial (not changed yet) state
    }
  },

  methods: {

    checkTaskDone(taskId) {
      this.$apollo.mutate({
        mutation: gql(`mutation {
          update_task (
            id: ` + taskId + `,
            status_id: 3,
          ) {
            id
          }
          }`
        )
      }).then(() => {
        this.snackbarDone = true;
        this.$store.commit("TASKS");
        this.$store.commit("TOPNAV");
        this.$store.commit("PROJ");
      });
    },

    refetch () {
        this.$apollo.queries.tasks.refetch();
    },

    onAddTask(taskName, description, timeEstimate, userId, dueDate, project, priority, status, attachments) {
      if(!timeEstimate) { timeEstimate = null; }
      if(!project) { project = null; }
      if(!userId) { userId = null; }

      this.$apollo.mutate({
        mutation: gql(`mutation {
          new_task (name: "` + taskName +`",
                description: "` + description + `",
                time_estimate: ` + timeEstimate + `,
                user_id: ` + userId + `,
                due_date: "` + dueDate + `",
                project_id: ` + project + `,
                priority: "` + priority + `",
                status: "` + status + `"
            ) {
              id
            }
          }`
        )
      }).then(response => {
        this.uploadFiles(attachments, response.data.new_task.id);
        if(userId && userId !== this.getUser.id) {
          this.sendParticipianEmail(userId, taskName, dueDate);
        }
        this.getLastTasks();
        this.$store.commit("TASKS");
        this.snackbar = true;
      });
    },

    uploadFiles(attachments, taskId) {
        for (let i = 0; i < attachments.length; i++) {
            let file = attachments[i];
            const data = new FormData();
            data.append('file', file);
            data.append('taskId', taskId);

            axios.post("https://dev.api.project.devg.cz/api/uploadFile", data);
        }
    },

    runTask(taskId)  {
      this.$apollo.mutate({
        mutation: gql(`mutation {
          time_record_action (task_id: ` + taskId + `) {
            id
            }
          }`
        ),
      }).then(() => {
        this.$store.commit("TOPNAV");
        this.$store.commit("TASKS");
        this.$store.commit("PROJ");
        this.getLastTasks();
      });


    },

    getLastTasks() {
      let category = "last";
      axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
        'dashboard_tasks(category:"' + category + '"){' +
          'data{' +
            'id,' +
            'name,' +
            'description,' +
            'time_estimate,' +
            'due_date,' +
            'client_approved,' +
            'attachments {' +
              'id,' +
              'name,' +
              'extension' +
            '}' +
            'invoice{' +
              'id' +
            '}' +
            'project{' +
              'id,' +
              'name' +
            '}' +
            'user{' +
              'id,' +
              'name' +
            '}' +
            'priority{' +
              'id,' +
              'name' +
            '}' +
            'status{' +
              'id,' +
              'name' +
            '}' +
            'timeRecords{' +
              'id,' +
              'start,' +
              'end' +
            '}' +
          '}' +
          '}' +
        '}'
      ).then(response => {
        this.dashboard_tasks = response.data.data.dashboard_tasks;
        console.log(this.dashboard_tasks);
    })
    },

    sendParticipianEmail(userId, taskName, dueDate) {
      axios.post('https://dev.api.project.devg.cz/api/sendEmailToUser', {
        userId: userId,
        taskName: taskName,
        dueDate: dueDate
      }).then(response => {
          console.log(response);
      });
    },

    showTaskDetailModal(parent, task) {
      console.log("SHOW MODAL");
      console.log("SHOW MODAL - task: " + task.name);
      parent.$refs.TaskDetail.runDialog(task);
    }

  }
};
</script>

<style scoped>

.taskLeft {
  width: 72%;
  display: inline-block;
  padding-left: 25px;
}

.taskName {
  font-family: "AkkuratLL-Light",sans-serif !important;
  font-weight: bold;
  font-size: 18px;
  display: inline-block;
  color: #000000 !important;
}

.dashBoradTask {
  border-radius: 14px;
  border: none !important;
}

.loadMoreCont {
  width: 100%;
  text-align: center;
}

.participians {
  display: inline-block;
}

.subTitle {
  font-size: 12px;
}

.text--primary {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 0;
}

.mx-auto {
  margin-bottom: 10px;
}

.rightTask {
  float: right;
  position: relative;
  /*top: -23px;*/
  display: flex;
  flex-direction: row;
}

.flexRight {
  min-width: 130px;
  height: auto;
}

.date {
  font-size: 17px;
}

.v-btn {
  margin-bottom: 0;
}

.v-avatar {
  color: #ffffff;
}

.v-card__text {
  padding-top: 5px;
  padding-bottom: 5px;
}

.priorityLow {
  font-family: "AkkuratLL-Bold",sans-serif !important;
  border-radius: 30px;
  background-color: #1dbe38;
  display: inline-block;
  color: #ffffff;
  padding: 0px 15px 0px 15px;
  font-weight: bold;
  font-size: 10px;
  /*width: 69px;*/
  text-align: center;
}

.priorityMedium {
  font-family: "AkkuratLL-Bold",sans-serif !important;
  border-radius: 30px;
  background-color: #ff6600;
  display: inline-block;
  color: #ffffff;
  padding: 0px 15px 0px 15px;
  font-weight: bold;
  font-size: 10px;
  /*width: 69px;*/
  text-align: center;
}

.priorityHigh {
  font-family: "AkkuratLL-Bold",sans-serif !important;
  border-radius: 30px;
  background-color: #ff0000;
  display: inline-block;
  color: #ffffff;
  padding: 0px 15px 0px 15px;
  font-weight: bold;
  font-size: 10px;
  /*width: 69px;*/
  text-align: center;
}

.viewTitle {
  font-family: "AkkuratLL-Bold",sans-serif !important;
  margin-top: 1%;
  margin-bottom: 1%;
  display: inline-block;
  flex: auto;
  font-size: 22px;
}

.taskStatus:before {
  content: ' \2022';
  margin-right: 2px;
  margin-left: 5px;
}

.checkButton {
  position: absolute;
  z-index: 0;
  top: 12px;
  left: 5px;
}

.checkButton:hover {
  color: #00bf9c;
}

@media only screen and (max-width: 1450px) {

  .taskLeft {
    width: 60%;
  }

}

@media only screen and (max-width: 900px) {

  .innerTask {
    display: flex;
    flex-direction: column;
  }

  .rightTask {
    float: none;
  }

  .taskLeft {
    width: 100%;
  }

  .taskStatus {
    display: block;
  }

  .projectAndStatus {
    margin-bottom: 10px;
  }

  .dashBoradTask {
    padding: 5px;
  }

  .rightTask {
    margin-top: 30px;
  }

  .flexRight {
    width: 33%;
    min-width: unset;
  }

  .flexRight span{
    justify-content: flex-end;
  }

  .date {
    font-size: 14px;
    margin-right: 0;
  }

  .checkButton {
    left: 0;
  }

  .checkButton i {
    font-size: 25px !important;
  }

}


</style>
