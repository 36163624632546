<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
    >

      <v-card>

        <v-card-title>
          <span class="headline">Upravit úkol</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
              <v-row>

                <v-col cols="12">
                  <v-text-field
                      label="Název úkolu*"
                      v-model="info.taskName"
                      required
                      :rules="rules"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                      label="Datum splnění"
                      v-model="info.dueDate"
                      type="date"
                      hint="Do kdy má být úkol hotový ?"
                      persistent-hint
                      required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                      label="Časová náročnost"
                      hint="Později slouží pro fakturaci klientovi"
                      persistent-hint
                      type="number"
                      v-model="info.timeEstimate"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-select
                      v-model="info.participans"
                      :items="members.data"
                      item-text="name"
                      item-value="id"
                      label="Úkol pro:"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-select
                      v-model="info.project"
                      :items="projects.data"
                      item-text="name"
                      item-value="id"
                      label="Projekt*"
                      :rules="[(v) => !!v || 'Vyberte prosím projekt']"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                      :items="['Low', 'Normal', 'High']"
                      label="Priorita"
                      v-model="info.priority"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-select
                      :items="['Backlog', 'In Progress', 'Done']"
                      label="Status"
                      required
                      v-model="info.status"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-select
                      :items="['Ano', 'Ne']"
                      label="Schváleno klientem ?"
                      required
                      v-model="info.approved"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-select
                      v-model="info.invoice"
                      :items="invoices.data"
                      item-text="name"
                      item-value="id"
                      label="Fakturováno ?"
                  ></v-select>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                      label="Popis úkolu"
                      v-model="info.description"
                      multi-line
                  ></v-textarea>
                </v-col>

                <v-col cols="12">
                  <input type="file" multiple @change="uploadFile">
                  <!--<v-file-input
                      counter
                      multiple
                      show-size
                      truncate-length="15"
                      @change="uploadFile"
                  ></v-file-input>-->
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <div v-for="att in task.attachments" :key="att.id">
                    <span @click="downloadFile(att.id)" style="color: #000000">
                      <v-icon v-if="att.extension === 'png' || att.extension === 'jpg' || att.extension === 'jpeg'" size="22" left>mdi-file-image-outline</v-icon>
                      <v-icon v-if="att.extension === 'pdf'" size="22" left>mdi-file-pdf-outline</v-icon>
                      <v-icon v-if="att.extension === 'xls' || att.extension === 'xlsx' || att.extension === 'csv'" size="22" left>mdi-microsoft-excel</v-icon>
                      <v-icon v-if="att.extension === 'docx' || att.extension === 'doc'" size="22" left>mdi-microsoft-word</v-icon>
                      <v-icon v-if="att.extension === 'zip' || att.extension === 'rar'" size="22" left>mdi-folder-zip</v-icon>
                      {{ att.name }}
                    </span>
                    <v-btn
                        style="margin-bottom: 0"
                        @click="deletedFile(att.id)"
                        icon
                        class="deleteFile"
                    >
                      <v-icon size="22" color="black">mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </div>
                </v-col>

              </v-row>
            </v-form>
          </v-container>
          <small>*požadováno</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="error"
              @click="deleteTask(task.id)"
          >
            Smazat úkol
          </v-btn>
          <v-btn
              color="primary"
              @click="updateTask(task.id)"
          >
            Uložit
          </v-btn>
          <v-btn
              color="blue darken-1"
              outlined
              @click="dialog = false"
          >
            Zavřít
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snackbar"
        color="success"
    >
      Úkol aktualizován

      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Zavřít
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
        v-model="snackbarDelete"
        color="success"
    >
      Úkol smazán

      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbarDelete = false"
        >
          Zavřít
        </v-btn>
      </template>
    </v-snackbar>

  </v-row>
</template>

<script>

import axios from 'axios';
import gql from "graphql-tag";

export default {


name: "TaskDetail",
  data: () => ({
    dialog: false,
    valid: true,
    snackbar: false,
    snackbarDeleted: false,
    task: {},
    rules: [
      value => !!value || 'Požadováno',
      value => (value && value.length >= 1) || 'Minimálně 1 znak',
    ],
    info: {
      taskName: '',
      dueDate: '',
      status: '',
      timeEstimate: null,
      participans: null,
      oldParticipans: null,
      project: null,
      priority: '',
      description: '',
      approved: 'Ne',
      invoice: null,
      attachments: [],
    },
    members: [],
    projects: [],
    invoices: [],
  }),

  methods: {

    deleteTask(taskId) {
      this.$confirm(
          "Opravdu si přejete tuto položku smazat ?",
          { color: "warning", title: "Upozornění", buttonTrueText: "Ano", buttonFalseText: "Ne", icon: "mdi-alert"  }
      ).then(res => {

        if (res) {
          this.$apollo.mutate({
            mutation: gql(`mutation {
              delete_task (id: ` + taskId + `) {
                id
                }
              }`
            )
          }).then(() => {
            this.$store.commit("TASKS");
            this.$store.commit("PROJ");
            this.snackbarDeleted = true;
            this.dialog = false;
            console.log("TASK DELETED");
          });
        }

      });
    },

    updateTask(taskId) {
      let priority = 2;
      if(this.info.priority === "Low") {
          priority = 1;
      } else if(this.info.priority === "High") {
          priority = 3;
      } else if(this.info.priority === "Normal") {
        priority = 2;
      }

      let approved = false;
      if(this.info.approved === "Ano") {
          approved = true;
      }

      let status = 1;
      if(this.info.status === "In Progress") {
          status = 2;
      } else if(this.info.status === "Done") {
          status = 3;
      } else if(this.info.status === "Backlog") {
        status = 1;
      }

      if(!this.info.participans){
          this.info.participans = 0;
      }
        if(!this.info.project){
            this.info.project = 0;
        }
      if(!this.info.invoice) {
          this.info.invoice = 0;
      }
      console.log(this.info.invoice);

      this.$apollo.mutate({
        mutation: gql(`mutation {
          update_task (
            id: ` + taskId + `,
            name: "` + this.info.taskName +`",
            description: "` + this.info.description + `",
            time_estimate: ` + this.info.timeEstimate + `,
            user_id: ` + this.info.participans + `,
            due_date: "` + this.info.dueDate + `",
            project_id: ` + this.info.project + `,
            priority_id: ` + priority + `,
            status_id: ` + status + `,
            client_approved: ` + approved + `,
            invoice_id: ` + this.info.invoice + `,
          ) {
            id
          }
        }`
        )
      }).then(() => {
        if(this.info.participans && this.info.participans !== this.info.oldParticipans && this.info.participans !== this.$store.state.user.id) {
            this.sendParticipianEmail(this.info.participans, this.info.taskName, this.info.dueDate);
        }
        this.$store.commit("TASKS");
        this.$store.commit("TOPNAV");
        this.$store.commit("PROJ");
        this.snackbar = true;
        this.dialog = false;
      });
    },

    // Call this from DashboardTask.vue & Project.vue & Dashboard when the dialog is invoked
    runDialog(task) {
      this.task = task;

      this.info.taskName = this.task.name;
      this.info.description = this.task.description;
      if(this.task.due_date) {
        this.info.dueDate = this.task.due_date.substr(0, 10);
      } else {
        this.info.dueDate = '';
      }
      if(this.task.user) {
        this.info.participans = this.task.user.id;
        this.info.oldParticipans = this.task.user.id;
      } else {
        this.info.participans = null;
        this.info.oldParticipans = null;
      }
      if(this.task.project) {
        this.info.project = this.task.project.id;
      } else {
        this.info.project = null;
      }
      this.info.priority = this.task.priority.name;
      this.info.status = this.task.status.name;
      if(this.task.client_approved) {
        this.info.approved = 'Ano';
      } else {
        this.info.approved = 'Ne';
      }
      if(this.task.invoice) {
        this.info.invoice = this.task.invoice.id;
      } else {
        this.info.invoice = null;
      }

      this.dialog = true;
    },

    loadSelectorMenuItems() {
        // Users
        axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
            'users{' +
              'data{' +
                'id,' +
                'name,' +
              '}' +
            '}' +
            '}'
        ).then(response => {
            this.members = response.data.data.users;
        });

        // Projects
        axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
            'projects(active: true){' +
              'data{' +
                'id,' +
                'name,' +
              '}' +
            '}' +
            '}'
        ).then(response => {
            this.projects = response.data.data.projects;
        });

        // Invoices
        axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
          'invoices{' +
            'data{' +
              'id,' +
              'name,' +
            '}' +
          '}' +
          '}'
        ).then(response => {
            this.invoices = response.data.data.invoices;
        });
    },

    uploadFile(event) {
      for (let i = 0; i < event.target.files.length; i++) {
        let file = event.target.files[i];
        const data = new FormData();
        data.append('file', file);
        data.append('taskId', this.task.id);

        axios.post("https://dev.api.project.devg.cz/api/uploadFile", data)
          .then(response => {
            console.log("Success!");
            console.log(response);
            if (this.task.attachments) {
              this.task.attachments.push(response.data);
            } else {
              this.task.attachments = [];
              this.task.attachments.push(response.data);
            }
          }).catch(error => {
          console.log({error});
        });
      }
    },

    downloadFile(id) {
        window.open("https://dev.api.project.devg.cz/api/downloadFile/" + id, '_blank');
    },

    deletedFile(id) {
      this.$confirm(
          "Opravdu si přejete tuto položku smazat ?",
          { color: "warning", title: "Upozornění", buttonTrueText: "Ano", buttonFalseText: "Ne", icon: "mdi-alert"  }
      ).then(res => {
        if(res) {
          this.$apollo.mutate({
            mutation: gql(`mutation {
              delete_attachment (
                id: ` + id + `
              ) {
                id
              }
              }`
            )
          }).then(() => {
            let index = this.task.attachments.findIndex(x => x.id === id);
            if(index >= 0) {
                this.task.attachments.splice(index, 1);
            }
            this.$store.commit("TASKS");
            this.$store.commit("PROJ");
          });
        }
      });
    },

    sendParticipianEmail(userId, taskName, dueDate) {
      axios.post('https://dev.api.project.devg.cz/api/sendEmailToUser', {
        userId: userId,
        taskName: taskName,
        dueDate: dueDate
      }).then(response => {
        console.log(response);
      });
    },

  },


  created() {
    console.log("TaskDetail: " + this.task);
    this.loadSelectorMenuItems();
  },
}
</script>

<style scoped>

  .deleteFile:hover i {
    color: red;
  }

</style>
