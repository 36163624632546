<template>
  <div style="padding: 0">
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            icon
            depressed
            v-bind="attrs"
            v-on="on"
            style="margin-bottom: 0; margin-left: 20px"
        >
          <v-icon style="margin-left: auto; margin-right: auto" color="#3D70B2" left>mdi-plus-circle</v-icon>
        </v-btn>
      </template>
      <template>
      <v-card>

        <v-toolbar
          color="primary"
          dark
        >
          Nový projekt
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <v-text-field
                      label="Název projektu*"
                      v-model="projectName"
                      required
                      :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-select
                      v-model="participans"
                      :items="members.data"
                      item-text="name"
                      item-value="id"
                      chips
                      attach
                      multiple
                      label="Uživatelé"
                  ></v-select>


                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-select
                      v-model="client"
                      :items="clients.data"
                      item-text="contactInfo.company"
                      item-value="id"
                      label="Klient"
                  ></v-select>
                </v-col>

              </v-row>
            </v-form>
          </v-container>
          <small>*Povinná pole</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              @click="addProject(projectName, participans, client)"
          >
            Uložit
          </v-btn>
          <v-btn
              color="primary"
              outlined
              @click="dialog = false"
          >
            Zavřít
          </v-btn>
        </v-card-actions>
      </v-card>
        <v-snackbar
            v-model="snackbar"
            color="success"
        >
          Projekt byl úspěšně přidán!

          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
              Zavřít
            </v-btn>
          </template>
        </v-snackbar>
      </template>
    </v-dialog>

  </div>
  </div>
</template>

<script>
import axios from "axios";
import gql from "graphql-tag";

export default {
  name: "AddProject",
  data: () => ({
    snackbar: false,
    projectName: '',
    members: [],
    clients: [],
    participans: [

    ],
    client: null,
    dialog: false,
    valid: true,
    rules: [
      value => !!value || 'Požadováno',
      value => (value && value.length >= 1) || 'Minimálně 1 znak',
    ],
  }),
  methods: {

    addProject(projectName, participans, client) {

      if (this.$refs.form.validate()) {
        this.snackbar = true

        setTimeout(() => {

          this.$apollo.mutate({
            mutation: gql(`mutation {
          new_project (name: "` + projectName +`",
                client_id: ` + client + `,
                users: [` + participans.toString() + `],
            ) {
              id
            }
          }`
            ),
            //refetchQueries: () => { this.refetchProjects() }
          }).then(() => {
            this.$store.commit("LNAV");
            this.$store.commit("CLIENTS");
            console.log("CHILD REFETCH AddProject.vue")
            this.$emit('refetchProjectMenu')
            this.dialog = false
          });

        }, 1500);


        // this.refetchProjects()
      }


    },

    loadSelectorMenuItems() {
      // Users
      axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
          'users{' +
          'data{' +
          'id,' +
          'name,' +
          '}' +
          '}' +
          '}'
      ).then(response => {
        this.members = response.data.data.users;
        console.log("MEMBERS: " + this.members);
      });


      // Clients
      axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
          'clients{' +
          'data{' +
          'id,' +
          'contactInfo{' +
          'company,' +
          '}' +
          '}' +
          '}' +
          '}'
      ).then(response => {
        this.clients = response.data.data.clients;
        console.log(this.clients);
      });

    }
  },

  created() {
    this.loadSelectorMenuItems();
  },
}
</script>

<style scoped>

</style>
