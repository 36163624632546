<template>
  <div style="padding: 0">
    <div>
      <v-dialog
          transition="dialog-bottom-transition"
          max-width="600"
          v-model="dialog"
          persistent
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="#108A00"
            style="color: #ffffff; z-index: 0"
            v-bind="attrs"
            v-on="on"
            :disabled="isConnected"
          >
            <span v-if="getUser.fakturoid_account">
              Propojeno s Fakturoidem
              <v-icon right>mdi-robot-outline</v-icon>
            </span>
            <span v-else>
              Propojit s Fakturoidem
              <v-icon right>mdi-robot-outline</v-icon>
            </span>
          </v-btn>
        </template>
        <template>
          <v-card>
            <v-toolbar
                color="primary"
                dark
            >Propojení s Fakturoidem</v-toolbar>

            <v-card-text>
              <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    md="12"
                >
                  <p style="margin-top: 20px">API klíč najdete ve <a style="color:#108A00" href="https://app.fakturoid.cz" target="_blank">Fakturoidu</a> v horním menu uživatele: <b>Nastavení > Uživatelský účet</b></p>
                  <v-text-field
                      label="Fakturoid API klíč*"
                      v-model="fakturoidApiKey"
                      required
                      :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="12"
                >
                  <v-text-field
                      label="Název Fakturoid účtu*"
                      v-model="fakturoidUsername"
                      required
                      :rules="rules"
                      hint="Nastavení > Uživatelský účet > Účet"
                      persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="12"
                >
                  <v-text-field
                      label="E-mail*"
                      v-model="fakturoidEmail"
                      required
                      :rules="emailRules"
                      hint="E-mail zadaný ve Fakturoidu"
                      persistent-hint
                  ></v-text-field>
                </v-col>


              </v-row>
              <small>*požadováno</small>

            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                  color="#108A00"
                  style="color: #ffffff"
                  @click="connectFakturoid(fakturoidApiKey, fakturoidUsername, fakturoidEmail)"
              >
                Propojit
              </v-btn>
              <v-btn
                  color="#108A00"
                  outlined
                  @click="dialog = false, fakturoidApiKey = ''"
              >Zavřít</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </div>
  </div>
</template>


<script>

import axios from "axios";

export default {
  name: "FakturoidConnect",
  data () {
    return {
      rules: [
        value => !!value || 'Požadováno',
      ],
      dialog: false,
      fakturoidApiKey: '',
      fakturoidUsername: '',
      fakturoidEmail: '',
      emailRules: [
        v => !!v || 'E-mail je požadován!',
        v => /.+@.+/.test(v) || 'E-mail musí být validní!',
      ],
    }
  },

  methods: {

    connectFakturoid(apiKey, username, email) {
      axios.get('https://dev.api.project.devg.cz/graphql/query?query=mutation{' +
          'update_user(fakturoid_api_key: "' + apiKey + '", fakturoid_account: "' + username + '", fakturoid_email: "' + email + '"){' +
            'id' +
          '}' +
          '}'
      ).then(response => {
          console.log(response);
          this.dialog = false;
      });

    }

  },

  computed: {

    isConnected() {
      return this.getUser.fakturoid_account !== null
    },

    getUser() {
      return this.$store.state.user
    },
  },

  created () {
    this.$store.dispatch('getUser');
  },

}
</script>

<style scoped>

</style>
