<template>
  <div style="padding: 0">
    <div>
      <v-dialog
          transition="dialog-bottom-transition"
          max-width="600"
          v-model="dialog"
          persistent
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              style="padding: 0; min-width: 40px"
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-plus-thick</v-icon>
          </v-btn>
        </template>
        <template>
          <v-card>
            <v-toolbar
                color="primary"
                dark
            >Nový workspace</v-toolbar>

            <v-card-text>
              <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
              >
              <v-row>
                <v-col
                    cols="6"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      label="Název workspace*"
                      v-model="workspaceName"
                      required
                      :rules="rules"
                  ></v-text-field>
                </v-col>

              </v-row>
              <small>*požadováno</small>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                  color="primary"
                  @click="addWorkspace(workspaceName)"
              >
                Vytvořit
              </v-btn>
              <v-btn
                  color="primary"
                  outlined
                  @click="dialog = false"
              >Zavřít</v-btn>
            </v-card-actions>
          </v-card>

          <v-snackbar
              v-model="snackbar"
              color="success"
          >
            Workspace byl úspěšně přidán!

            <template v-slot:action="{ attrs }">
              <v-btn
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Zavřít
              </v-btn>
            </template>
          </v-snackbar>
        </template>
      </v-dialog>
    </div>

  </div>
</template>

<script>
import gql from "graphql-tag";

const GET_WORKSPACES = gql`
  query getWorkspaces {
    workspaces {
            data {
              id
              name
            }
          }
  }
`;

export default {
name: "AddWorkspace",
  data () {
    return {
      snackbar: false,
      rules: [
        value => !!value || 'Požadováno',
        value => (value && value.length >= 1) || 'Minimálně 1 znak',
      ],
      dialog: false,
      workspaceName: '',
      valid: true,
    }
  },
  apollo: {
    workspaces: {
      query: GET_WORKSPACES
    }
  },
  methods: {
    refetch () {
      this.$apollo.queries.clients.refetch()
    },

    addWorkspace(workspaceName) {
      console.log("WORKSPACE NAME: " + workspaceName);

      if (this.$refs.form.validate()) {
        this.$apollo.mutate({
          mutation: gql(`mutation {
              new_workspace (name: "` + workspaceName + `") {
                id
                }
              }`
          ),
          refetchQueries: () => [{ query: GET_WORKSPACES }]
        }).then((res) => {
          this.snackbar = true
          console.log(res)
          window.location.href = "/";
        }).catch((err) => console.log(err))
      }


    }
  }
}
</script>

<style scoped>

</style>
