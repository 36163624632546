<template>
  <div>
    <div class="titleAndBtn">
      <h2 class="viewTitle">Seznam klientů</h2>
      <AddClient @addClientChild="onAddClient" />
    </div>

    <v-simple-table fixed-header v-if="clients">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">Jméno</th>
          <th class="text-left">Příjmení</th>
          <th class="text-left">Společnost</th>
          <th class="text-left">Adresa</th>
          <th class="text-left">IČO</th>
          <th class="text-left">DIČ</th>
          <th class="text-left">Hodinová sazba</th>
          <th class="text-left">Bankovní účet</th>
          <th class="text-left">Plátce DPH</th>
          <th class="text-left">Projekt</th>
          <th class="text-left">Akce</th>
        </tr>
        </thead>
        <tbody v-if="clients" >
        <tr v-for="client in clients.data" :key="client.id">
          <td>{{ client.contactInfo.name }}</td>
          <td>{{ client.contactInfo.surname }}</td>
          <td>{{ client.contactInfo.company }}</td>
          <td>{{ client.contactInfo.address }}</td>
          <td>{{ client.contactInfo.ico }}</td>
          <td>{{ client.contactInfo.dic }}</td>
          <td>{{ client.contactInfo.hour_rate }}</td>
          <td>{{ client.contactInfo.bank_account }}</td>
          <td v-if="client.contactInfo.platce_DPH !== null">
            Ano
          </td>
          <td v-else>
            Ne
          </td>
          <td class="projects" v-if="client.projects">
            <span v-for="project in client.projects" :key="project.id">
              {{ project.name }}<span class="separator" v-if="client.projects.length > 1">, </span>
            </span>
          </td>
          <td v-else>
            Bez projektu
          </td>
          <td>
            <v-btn
                style="display: inline-block; margin-right: 10px"
                icon
                @click="() => showClientDetailModal(client)"
            >
              <v-icon>mdi-account-edit-outline</v-icon>
            </v-btn>
            <v-btn
                style="display: inline-block"
                icon
                @click="() => deleteClient(client.id)"
            >
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <ClientDetails @openSnackbarUpdated="onOpenSnackbarUpdated" @closeDialogChild="onCloseDialog" :openDialog="dialog" :clientInfo="client_info"></ClientDetails>

    <v-snackbar
        v-model="snackbar"
        color="success"
    >
      Klient úspěšně přidán!

      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Zavřít
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
        v-model="snackbarUpdated"
        color="success"
    >
      Klient úspěšně aktualizován

      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbarUpdated = false"
        >
          Zavřít
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
        v-model="snackbarDeleted"
        color="success"
    >
      Klient smazán!

      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbarDeleted = false"
        >
          Zavřít
        </v-btn>
      </template>
    </v-snackbar>

  </div>

</template>

<script>
import gql from "graphql-tag";
import AddClient from "@/components/AddClient";
import ClientDetails from "@/components/ClientDetails";

const GET_CLIENTS = gql`
  query getClients {
    clients {
            data {
              id
              contactInfo {
                name
                surname
                address
                company
                ico
                dic
                hour_rate
                bank_account
                platce_DPH
              }
              projects {
                id
                name
              }
            }
          }
  }
`;


export default {
  name: "Clients",
  components: {ClientDetails, AddClient},
  data: () => ({
    client_info: {},
    dialog: false,
    snackbarDeleted: false,
    snackbar: false,
    snackbarUpdated: false,
    timeout: 2000,
  }),
  apollo: {
    clients: {
      query: GET_CLIENTS
    }
  },
  methods:  {

    onCloseDialog() {
      this.dialog = false;
    },

    onOpenSnackbarUpdated() {
      this.dialog = false;
      this.snackbarUpdated = true
    },

    showClientDetailModal(clientInfoData) {

      if (clientInfoData.contactInfo.platce_DPH === null) {
        clientInfoData.contactInfo.platce_DPH = 0
      } else {
        clientInfoData.contactInfo.platce_DPH = 1
      }

      console.log("CLIENT: " + clientInfoData.contactInfo.platce_DPH)

      this.client_info = clientInfoData;
      this.dialog = true;
      /*console.log(parent)
      console.log(client)
      parent.$refs.ClientDetails.dialog = true;
      parent.$refs.ClientDetails.client = client;*/
      //parent.$refs.ClientDetails.setupTaskInfo();
    },

    deleteClient(clientId) {
      console.log(clientId)

      this.$confirm(
          "Opravdu si přejete tuto položku smazat ?",
          { color: "warning", title: "Upozornění", buttonTrueText: "Ano", buttonFalseText: "Ne", icon: "mdi-alert"  }
      ).then(res => {

        if (res) {
          this.$apollo.mutate({
            mutation: gql(`mutation {
              delete_client (id: ` + clientId + `) {
                id
                }
              }`
            ),
            refetchQueries: () => [{ query: GET_CLIENTS }]
          })
          this.snackbarDeleted = true;
        }
      });
    },

    refetch () {
      this.$apollo.queries.clients.refetch()
    },

    onAddClient(name, surname, company, address, ico, dic, hrate, bankacc, platce_DPH) {
      console.log("PARENT: " + name, surname, company, address, ico, dic, hrate, bankacc, platce_DPH)
      this.dialog = false
      console.log("Client created!")

      if (!hrate) {
        hrate = null;
      }

      this.$apollo.mutate({
        mutation: gql(`mutation {
              new_client (name: "` + name + `", surname: "` + surname + `", company: "` + company + `", address: "` + address + `", ICO: "` + ico + `", DIC: "` + dic + `", hour_rate: ` + hrate + `, bank_account: "` + bankacc + `", platce_DPH: ` + platce_DPH + `) {
                id
                }
              }`
        ),
        refetchQueries: () => [{ query: GET_CLIENTS }]
      })
      this.snackbar = true
      this.dialog = false
    },
  },

  watch: {
    "$store.state.clients": {
      handler: function() {
        console.log("WATCH CLIENTS");
        this.refetch();
      },
      immediate: true // provides initial (not changed yet) state
    }
  },

  computed: {
    getUser() {

      return this.$store.state.user
    },

  },

  created () {
    this.$store.dispatch('getUser');
  },
};
</script>

<style scoped>

.projects span:last-child .separator {
  display: none;
}

.viewTitle {
  font-family: "AkkuratLL-Bold",sans-serif !important;
  margin-top: 1%;
  margin-bottom: 1%;
  display: inline-block;
  flex: auto;
}

</style>
