<template>
  <div>
    <div class="titleAndBtn">
      <h1 class="viewTitle">Reporty</h1>
      <v-btn
          v-if="workspaceAdmin"
          color="primary"
          outlined
          class="ma-2"
          :to="{ name: 'ReportsCompany'}"
          style="z-index: 0"
      >
        Fakturuji za firmu
      </v-btn>
    </div>
    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
    >
      Nejdříve je potřeba se přihlásit!

      <template v-slot:action="{ attrs }">
        <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <div class="filterCont">
      <v-row>
        <v-col
            cols="12"
            sm="6"
            md="2"
        >
        <div class="text-center">
          <v-select
              v-model="member"
              :items="members.data"
              item-text="name"
              item-value="id"
              label="Člen týmu"
          ></v-select>
        </div>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            md="2"
        >
        <div class="text-center">
          <v-select
              v-model="project"
              :items="projects.data"
              item-text="name"
              item-value="id"
              label="Projekt"
          ></v-select>
        </div>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            md="2"
        >
        <div class="text-center">
          <v-select
              v-model="client"
              :items="clients.data"
              item-text="contactInfo.company"
              item-value="id"
              label="Klient"
          ></v-select>
        </div>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            md="2"
        >
          <DatePicker :date.sync="startDate"/>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            md="2"
        >
          <DatePicker :date.sync="endDate"/>
        </v-col>

      </v-row>
      <v-row>
        <v-btn
            style="z-index: 0; margin-left: 12px"

            color="primary"
            @click="filterRecords(member, project, client, startDate, endDate)"
        >
          FILTROVAT
        </v-btn>
        <v-btn
            style="z-index: 0; margin-left: 12px"
            color="primary"
            outlined
            @click="removeFiltres"
        >
          ZRUŠIT FILTR
        </v-btn>
      </v-row>
    </div>

    <h4
      style="margin-bottom: 20px; margin-top: 20px"
    >
      <b>Naměřený čas:</b>&nbsp;{{ totalTime }}
    </h4>

    <v-simple-table
        fixed-header
    >
      <template v-slot:default>
        <thead v-if="reports">
        <tr>
          <th>
            <v-checkbox v-model="selectAll" />
          </th>
          <th class="text-left">Čas</th>
          <th class="text-left">Začátek</th>
          <th class="text-left">Konec</th>
          <th class="text-left">Jméno</th>
          <th class="text-left">Úkol</th>
          <th class="text-left">Projekt</th>
          <th class="text-left">Společnost</th>
          <th style="padding: 0" class="text-left">Akce</th>
        </tr>
        </thead>
        <tbody v-if="reports">
        <tr v-for="report in reports.data" :key="report.id">
          <td>
            <v-checkbox v-if="!report.invoice && report.user.id === getUser.id"
                v-model="selectedReports"
                :value="report.id"
                @click="checkMultipleClients(report.task.project)"
            ></v-checkbox>
            <v-tooltip v-else top>
              <template v-slot:activator="{ on, attrs }">
                <span
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-checkbox
                      disabled
                  >

                </v-checkbox>
                </span>
              </template>
              <span>Fakturovat nelze cizí a vyfakturované reporty</span>
            </v-tooltip>

          </td>
          <td>{{ report.time_spend }}</td>
          <td>{{ report.start | moment("DD. MM. YYYY HH:mm") }}</td>
          <td>{{ report.end | moment("DD. MM. YYYY HH:mm") }}</td>
          <td>{{ report.user.name }}</td>
          <td>{{ report.task.name }}</td>
          <td>{{ report.task.project.name }}</td>
          <td v-if="report.task.project.client != null">{{ report.task.project.client.contactInfo.company }}</td>
          <td v-else> Žádná společnost</td>
          <td style="padding: 0"><v-btn
              v-if="report.user.id === getUser.id"
              style="display: inline-block; margin-right: 1px"
              icon
              @click="() => showReportDetailModal(report)"
          >
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
            <v-btn
                v-if="report.user.id === getUser.id"
                style="display: inline-block"
                icon
                @click="() => deleteReport(report.id)"
            >
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>

            <v-tooltip v-else top>
              <template v-slot:activator="{ on, attrs }">
                <span
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-btn
                      style="display: inline-block; margin-right: 1px"
                      icon
                      disabled
                  >
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
                  <v-btn
                      style="display: inline-block"
                      icon
                      disabled
                  >
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>


                </span>
              </template>
              <span>Upravovat lze pouze svoje reporty</span>
            </v-tooltip>

          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>




      <v-btn
        color="primary"
        class="ma-2"
        style="z-index: 0"
        :disabled="isSelected"
        :to="{ name: 'Invoices',
                query: {
                  reports: selectedReports
                }
        }"
      >
        Vytvořit fakturu
      </v-btn>
      <ReportDetails @closeDialogChild="onCloseDialog" :openDialog="dialog" :reportInfo="report_info" :invoices="invoices"></ReportDetails>

    <v-snackbar
        v-model="snackbarMultipleClients"
    >
      Vybral jste reporty s více klienty.

      <template v-slot:action="{ attrs }">
        <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbarMultipleClients = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import axios from "axios";
import ReportDetails from "@/components/ReportDetails";
import gql from "graphql-tag";

export default {
  name: "Reports",
  components: {ReportDetails, DatePicker},
  data: () => ({
    snackbarMultipleClients: false,
    reports: [],
    startDate: new Date().toISOString().substr(0, 10),
    endDate: new Date().toISOString().substr(0, 10),
    snackbar: false,
    timeout: 2000,
    member: '',
    client: '',
    project: '',
    members: [],
    projects: [],
    clients: [],
    invoices: [],
    totalTime: "",
    selectedReports: [],
    selected: [],
    selectAll: false,
    count: 20,
    report_info: {
      startTime: '',
      endTime: '',
    },
    dialog: false,
    time_spend: '',
    workspaceAdmin: false,
    clientIds: []
  }),

  watch: {
    "$store.state.reports": {
      handler: function() {
        this.filterRecords();
        this.dialog = false;
      },
      immediate: true // provides initial (not changed yet) state
    },

      "$store.state.user": {
          handler: function() {
              this.getPermissions();
          },
          immediate: true // provides initial (not changed yet) state
      }
  },

  methods: {

    checkMultipleClients(project) {

      if (project.client !== null) {
        let clientId = project.client.contactInfo.id;

        this.clientIds.push(project.client.contactInfo.id)

        this.clientIds.sort();
        if (this.clientIds[0] !== this.clientIds[this.clientIds.length -1] && this.clientIds.length > 1) {
          this.snackbarMultipleClients = true;
          this.clientIds.splice(this.clientIds[clientId], 1)
        } else {
          this.snackbarMultipleClients = false;
        }
        //return this.clientIds[0] === this.clientIds[this.clientIds.length -1]

        /*
        for (let i = 0; i < this.clientIds.length; i++) {
          if (this.clientIds[i] === clientId && !this.clientIds.includes(clientId)) {
            this.snackbarMultipleClients = true;
            this.clientIds = [];
            //this.clientIds.splice(i, 1)
          } else if (this.clientIds[i] !== clientId) {
            this.snackbarMultipleClients = true;
            this.clientIds.splice(i, 1)
          } else if (this.clientIds.length === 0) {
            this.snackbarMultipleClients = false;
          }
        }*/

      }

    },

    removeFiltres() {
      this.member = '';
      this.client = '';
      this.project = '';

      this.filterRecords();
      console.log("FILTRES REMOVED")
    },

    showReportDetailModal(report) {
      this.report_info = report;

      if(this.report_info.start) {
        this.report_info.startTime = this.report_info.start.substr(11, 8);
        this.report_info.start = this.report_info.start.substr(0, 10);
      } else {
        this.report_info.start = '';
        this.report_info.startTime = '';
      }

      if(this.report_info.end) {
        this.report_info.endTime = this.report_info.end.substr(11, 8);
        this.report_info.end = this.report_info.end.substr(0, 10);
      } else {
        this.report_info.end = '';
        this.report_info.endTime = '';
      }
      this.dialog = true;

    },

    deleteReport(reportId) {
      this.$confirm(
          "Opravdu si přejete tento záznam smazat ?",
          { color: "warning", title: "Upozornění", buttonTrueText: "Ano", buttonFalseText: "Ne", icon: "mdi-alert"  }
      ).then(res => {

        if (res) {
          this.$apollo.mutate({
            mutation: gql(`mutation {
              delete_time_record (id: ` + reportId + `) {
                id
                }
              }`
            ),

          }).then(() => {
            this.$store.commit("REPORTS");
          });
        }
      });
    },

    onCloseDialog() {
      this.dialog = false;
    },

    refetch () {
      this.$apollo.queries.reports.refetch()
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },

    filterRecords(member, project, client, startDate, endDate) {

      let argumentString = '';
      if(member) { argumentString += 'member_id:' + member + ','; }
      if(project) { argumentString += 'project_id:' + project + ','; }
      if(client) { argumentString += 'client_id:' + client + ','; }
      if(startDate) { argumentString += 'from_date:"' + startDate + '",'; }
      if(endDate) { argumentString += 'to_date:"' + endDate + '",'; }

      if(argumentString) { argumentString = '(' + argumentString + ')'; }

      axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
          'reports' + argumentString + '{' +
            'data{' +
              'id,' +
              'start,' +
              'end,' +
              'user{' +
                'id,' +
                'name,' +
              '}' +
              'invoice{' +
                'id' +
              '}' +
              'task{' +
                'id,' +
                'name,' +
                'project{' +
                  'id,' +
                  'name,' +
                  'client{' +
                    'contactInfo{' +
                      'id,' +
                      'company,' +
                    '}' +
                  '}' +
                '}' +
              '}' +
            '}' +
          '}' +
          '}'
      ).then(response => {
          this.reports = response.data.data.reports;
          for (let i = 0; i < this.reports.data.length; i++) {
              if(this.reports.data[i].invoice) {
                  this.reports.data[i].invoice = this.reports.data[i].invoice.id;
              } else {
                  this.reports.data[i].invoice = null;
              }
          }
          this.computeTotalTime();
          this.computeReportTime();
      })
    },

    computeReportTime() {
      let i;
      for (i = 0; i < this.reports.data.length; i++) {
        let reportSec = (Date.parse(this.reports.data[i].end) - Date.parse(this.reports.data[i].start)) / 1000;

        let reportHours =parseInt( reportSec / 3600 );
        reportSec = reportSec % 3600;

        let reportMinutes = parseInt( reportSec / 60 );
        reportSec = reportSec % 60;

        reportHours = (reportHours < 10) ? "0" + reportHours : reportHours;
        reportMinutes = (reportMinutes < 10) ? "0" + reportMinutes : reportMinutes;
        reportSec = (reportSec < 10) ? "0" + reportSec : reportSec;

        this.reports.data[i]['time_spend'] = reportHours + ":" + reportMinutes +":" + reportSec;
      }
    },


    computeTotalTime() {
      console.log("total Time");
      let total = 0;
      this.reports.data.forEach(
        report => {
          total += Date.parse(report.end) - Date.parse(report.start);
        }
      );

      let sec_num = parseInt(total / 1000);
      let hours   = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      let seconds = sec_num - (hours * 3600) - (minutes * 60);

      if (hours   < 10) {hours   = "0"+hours;}
      if (minutes < 10) {minutes = "0"+minutes;}
      if (seconds < 10) {seconds = "0"+seconds;}
      this.totalTime =  hours+':'+minutes+':'+seconds;
    },

    loadFilterItems() {
      this.$store.commit("DATE");

      // Users
      axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
          'users{' +
            'data{' +
              'id,' +
              'name,' +
            '}' +
          '}' +
          '}'
      ).then(response => {
        this.members = response.data.data.users;
      });

      // Projects
      axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
          'projects{' +
            'data{' +
              'id,' +
              'name,' +
            '}' +
          '}' +
          '}'
      ).then(response => {
        this.projects = response.data.data.projects;
      });

      // Clients
      axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
          'clients{' +
            'data{' +
              'id,' +
              'contactInfo{' +
                'company' +
              '}' +
            '}' +
          '}' +
          '}'
      ).then(response => {
        this.clients = response.data.data.clients;
      });

      // Invoices
      axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
        'invoices{' +
          'data{' +
            'id,' +
            'name,' +
          '}' +
        '}' +
        '}'
      ).then(response => {
        this.invoices = response.data.data.invoices;
      });

      // Setup start date
      let startDate = new Date();
      startDate.setMonth(startDate.getMonth()-1);
      this.startDate = startDate.toISOString().substr(0, 10);

      this.getPermissions();
    },

      getPermissions() {
          if(this.getUser.adminWorkspaces.find( ({ workspace_id }) => workspace_id == this.getUser.active_workspace_id )) {
              this.workspaceAdmin = true;
          } else  {
              this.workspaceAdmin = false;
          }
      }

  },
  computed: {

    isSelected() {
      console.log("(Reports.vue) REPORTS ID's: " + this.selectedReports );
      return this.selectedReports.length <= 0
    },

    dateRangeText () {
      return this.dates.join(' ~ ')
    },
    getUser() {
      return this.$store.state.user
    },
  },

  created () {
    this.$store.dispatch('getUser');

    this.loadFilterItems();
    this.filterRecords(null, null, null, this.startDate, this.endDate);
  },
}
</script>

<style scoped>

.reportGraph {
  /*max-width: 50%;*/
}

.viewTitle {
  font-family: "AkkuratLL-Bold",sans-serif !important;
  margin-top: 1%;
  margin-bottom: 1%;
  display: inline-block;
  flex: auto;
}

</style>
