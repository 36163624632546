<template>
  <div>
    <h1 class="viewTitle">Vytvořené faktury</h1>

    <v-simple-table fixed-header v-if="invoices.data.length !== 0">
      <template v-slot:default>
        <thead v-if="invoices">
        <tr>
          <th class="text-left">Označení faktury</th>
          <th class="text-left">Klient</th>
          <th class="text-left">Datum vystavení</th>
          <th class="text-left">Částka</th>
          <th class="text-left">Akce</th>
        </tr>
        </thead>
        <tbody v-if="invoices">
        <tr v-for="invoice in invoices.data" :key="invoice.id">
          <td>{{ invoice.name }}</td>
          <td v-if="invoice.client">{{ invoice.client.contactInfo.name }}</td>
          <td v-else>-</td>
          <td>{{ invoice.issue_date }}</td>
          <td>{{ invoice.total_price }} Kč</td>
          <td>
            <v-btn
                icon
                style="margin-bottom: 0; z-index: 0; margin-right: 10px"
                :to="{ name: 'Invoices',
                query: {
                invoiceId: invoice.id
                }
                }"
            >
              <v-icon left>mdi-file-eye</v-icon>
            </v-btn>
            <v-btn
                style="display: inline-block;margin-bottom: 0"
                icon
                @click="() => deleteInvoice(invoice.id)"
            >
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <p v-else>Žádné faktury zatím nebyly vystaveny.</p>

  </div>
</template>

<script>

import gql from "graphql-tag";

const GET_INVOICES = gql`
  query getInvoices {
    invoices {
            data {
              id
              name
              issue_date
              total_price
              client {
                id
                contactInfo {
                  id
                  name
                  surname
                }
              }
            }
          }
  }
`;

export default {
  name: "InvoicesHistory",
  data: () => ({
    selectedInvoice: [],
  }),
  apollo: {
    invoices: {
      query: GET_INVOICES
    }
  },
  methods: {

    deleteInvoice(invoiceId) {
      console.log(invoiceId)

      this.$confirm(
          "Opravdu si přejete tuto fakturu smazat ?",
          { color: "warning", title: "Upozornění", buttonTrueText: "Ano", buttonFalseText: "Ne", icon: "mdi-alert"  }
      ).then(res => {

        if (res) {
          this.$apollo.mutate({
            mutation: gql(`mutation {
              delete_invoice (id: ` + invoiceId + `) {
                id
                }
              }`
            ),
            refetchQueries: () => [{ query: GET_INVOICES }]
          })
        }
      });
    },

  }

}
</script>

<style scoped>

.viewTitle {
  font-family: "AkkuratLL-Bold",sans-serif !important;
  margin-top: 1%;
  margin-bottom: 1%;
  display: inline-block;
  flex: auto;
}

</style>
