<template>
  <div>

    <DasboardTask ref="DasboardTask"></DasboardTask>

    <TaskDetail ref="TaskDetail"></TaskDetail>

   <v-container
       style="text-align: center"
       class="mt-3" v-if="getUser.nickname === undefined"
   >
     <v-flex>
       <div>

         <h1 style="text-align: center">Vítejte v aplikaci Pro Track. </h1>
         <p>Pro další pokračování se prosím přihlaste nebo se zaregistrujte.</p>

         <v-btn color="primary" to="/login">Přihlásit se</v-btn> <br>
         <p class="caption">Nemáte ještě účet ?</p>
         <v-btn outlined color="primary" to="/register">Registrovat se</v-btn>

       </div>
     </v-flex>
   </v-container>


    <h2 v-if="getUser.nickname !== undefined" class="viewTitle">Následující úkoly</h2>

    <UserTasks ref="UserTasks"></UserTasks>

  </div>
</template>

<script>

import DasboardTask from "@/components/DasboardTask";
import UserTasks from "@/components/UserTasks";
import TaskDetail from "@/components/TaskDetail";

export default {
  name: "Dashboard",
  components: {
    TaskDetail,
    UserTasks,
    DasboardTask
  },
  data: () => ({
    snackbar: false,
    snackbarLogin: false,
  }),
  computed: {
    getUser() {

      return this.$store.state.user
    },

  },

  created () {
    this.$store.dispatch('getUser');

  },
  methods: {
  }

};
</script>

<style scoped>

.viewTitle {
  font-family: "AkkuratLL-Bold",sans-serif !important;
  margin-top: 1%;
  margin-bottom: 1%;
  display: inline-block;
  flex: auto;
  font-size: 22px;
}

.viewTitle {
  margin-top: 30px;
}

</style>
