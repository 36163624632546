import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { createProvider } from "./vue-apollo";
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
import VueMoment from "vue-moment";
import VueAxios from 'vue-axios'
import axios from "axios";
import VueSocialauth from 'vue-social-auth'
import VuetifyConfirm from 'vuetify-confirm'
import moment from 'moment-timezone'

axios.defaults.withCredentials = true
Vue.config.productionTip = false;

Vue.use(VueMoment, {
  moment,
})
Vue.use(VueAxios, axios)
Vue.use(VuetifyConfirm, {
  vuetify
});


new Vue({
  router,
  VuetifyConfirm,
  VueSocialauth,
  store,
  vuetify,
  VueFilterDateFormat,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount("#app");
