<template>
  <v-footer padless app>
    <v-col class="text-center" cols="12" style="font-size: 13px; padding-top: 5px; padding-bottom: 5px">
      <strong>Pro Track</strong> - {{ new Date().getFullYear() }}

    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: "BottomNavgiation",
  data() {
    return {
      myprop: null
    };
  },
  watch: {
    "$store.state.topNav": {
      handler: function(nv) {
        this.myprop = nv;
      },
      immediate: true // provides initial (not changed yet) state
    }
  },
  methods: {
    incr() {
      this.$store.commit("TOPNAV");
    }
  }
};
</script>

<style scoped></style>
