<template>
  <v-card height="100vh" width="256" class="mx-auto">

      <v-list-item class="AppName">
        <v-list-item-content>
          <router-link to="/" class="title color-blue">
            Pro Track
          </router-link>
          <v-list-item-subtitle>
            Project Management Tool
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>



      <v-list dense nav>
        <v-btn
            to="/"
            v-ripple="{class: `color-blue`}"
            active-class="color-blue"
            class="btnLeftPanel"
            block
            depressed
            color="transparent"
        >
          <v-icon
              left
          >
            mdi-menu
          </v-icon>
          ÚKOLY
        </v-btn>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title style="font-weight: bold; letter-spacing: 1.25px; font-size: 14px">
              <v-icon color="black" left style="display: inline-block">mdi-briefcase-edit-outline</v-icon>
              PROJEKTY
              <AddProject @refetchProjectMenu="refetch" style="display: inline-block"></AddProject>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <div v-if="projects.data.length !== 0">
        <v-list-item class="activeProject" v-for="project in projects.data" :key="project.id" link style="min-height: 30px !important">
          <v-list-item-content
              v-if="project.active === true"
              style="padding: 0 !important;"
          >
            <v-list-item-title>
              <router-link
                  :to="{ name: 'Project', params: {projectID: project.id}}"
              >
                <v-icon size="22" left>mdi-bookmark</v-icon>
                {{ project.name }}
              </router-link>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        </div>
        <div class="noProject" v-else>Žádné projekty</div>
        <v-expansion-panels
          accordion
          flat
          class="oldProjects"
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              Ukončené projekty
            </v-expansion-panel-header>
            <v-expansion-panel-content
                v-for="project in projects.data" :key="project.id"
            >
              <router-link
                  v-if="project.active === false"
                  :to="{ name: 'Project', params: {projectID: project.id}}"
              >
                <v-icon size="20" left>mdi-history</v-icon>
                {{ project.name }}
              </router-link>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-list>

      <v-list nav dense class="btnGroup">
        <v-btn
            to="/clients"
            raised
            depressed
            v-ripple="{class: `color-blue`}"
            active-class="color-blue"
            class="btnLeftPanel"
            block
            color="transparent"
        >
          <v-icon left>mdi-account-box-multiple-outline</v-icon>
          KLIENTI
        </v-btn>
        <v-btn
            to="/invoices"
            raised
            depressed
            v-ripple="{class: `color-blue`}"
            active-class="color-blue"
            class="btnLeftPanel"
            block
            color="transparent"
        >
          <v-icon left>mdi-file-document-outline</v-icon>
          VYTVOŘIT FAKTURU
        </v-btn>
        <v-btn
            to="/reports"
            raised
            depressed
            v-ripple="{class: `color-blue`}"
            active-class="color-blue"
            class="btnLeftPanel"
            block
            color="transparent"
        >
          <v-icon left>mdi-chart-donut-variant</v-icon>
          REPORTY
        </v-btn>
      </v-list>


    <div class="bottomNav">
      <p style="margin-bottom: 0">Workspace:</p>
      <ChangeWorkspace></ChangeWorkspace>
      <AddWorkspace></AddWorkspace>
    </div>

  </v-card>
</template>

<script>
import gql from "graphql-tag";
import ChangeWorkspace from "@/components/ChangeWorkspace";
import AddWorkspace from "@/components/AddWorkspace";
import AddProject from "@/components/AddProject";


const GET_PROJECTS = gql`
  query getProjects {
    projects {
      data {
        id
        name
        active
      }
    }
  }
`;

export default {
  name: "LeftNavigation",
  components: {AddProject, AddWorkspace, ChangeWorkspace},
  data() {
    return {

    };
  },
  apollo: {
    projects: {
      query: GET_PROJECTS
    }
  },

  watch: {
    "$store.state.leftNav": {
      handler: function() {
        console.log("WATCH PROJECT");
        this.refetch();
      },
      immediate: true // provides initial (not changed yet) state
    }
  },

  methods: {

    refetch () {
      console.log("REFETCH PROJECT")
      this.$apollo.queries.projects.refetch();
      /*this.$apollo.query({
        refetchQueries: () => [{ query: GET_PROJECTS }]
      })*/
    },



    showProjectDetail(parent, task) {
      console.log("SHOW PROJECT");
      parent.$refs.Task.task = task;

    }
  }
};
</script>

<style>

  .v-btn--active {
    border-radius: 0 !important;
    background: rgb(255,255,255);
    background: linear-gradient(98deg, rgba(255,255,255,1) 36%, rgba(189,218,255,1) 100%);
  }

  .btnLeftPanel:before {
    background: transparent !important;
    font-weight: 500;
    border-radius: 0 !important;
  }

  .btnLeftPanel {
    font-family: "AkkuratLL-Bold",sans-serif !important;
    font-weight: 600 !important;
    justify-content: left !important;
  }

  .btnLeftPanel span {
    font-family: "AkkuratLL-Bold",sans-serif !important;
  }

  .bottomNav {
    position: absolute !important;
    bottom: 60px;
    left: 10px;
  }

  .bottomNav > div {
    display: inline-block;
  }

  .title {
    text-decoration: none;
  }


  .v-btn {
    margin-bottom: 15px;
    z-index: 9999;
  }

  .v-btn:hover:before {
    background-color: #3D70B2 !important;
  }

  .v-navigation-drawer__border {
    display: none !important;
    width: 0 !important;
  }

  .v-navigation-drawer__content {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
  }

  .noProject {
    margin-left: 42px;
    font-size: 14px;
  }

  .v-expansion-panel-content__wrap {
    font-size: 13px;
    padding-bottom: 10px !important;
  }

  .v-expansion-panel-content__wrap:empty, .activeProject:empty{
    display: none;
  }

  .v-application--is-ltr .v-expansion-panel-header__icon {
    margin-left: 10px !important;
  }

  .oldProjects .v-expansion-panel-header {
    font-size: 13px !important;
    padding-left: 13px !important;
  }

  .oldProjects .v-expansion-panel-content__wrap {
    padding-left: 13px !important;
  }

  .oldProjects .v-application--is-ltr .v-expansion-panel-header {
    padding-bottom: 0 !important;
  }

</style>
