<template>
  <v-dialog
      v-model="openDialog"
      persistent
      max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Upravit záznam</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
          >
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    label="Začátek - datum"
                    type="date"
                    required
                    v-model="reportInfo.start"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    label="Čas"
                    type="text"
                    required
                    v-model="reportInfo.startTime"
                    :rules="timeRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    label="Konec - datum"
                    type="date"
                    required
                    v-model="reportInfo.end"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    label="Čas"
                    type="text"
                    required
                    v-model="reportInfo.endTime"
                    :rules="timeRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-select
                        v-model="reportInfo.invoice"
                        :items="invoices.data"
                        item-text="name"
                        item-value="id"
                        label="Fakturováno ?"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            @click="updateReport()"
        >
          Aktualizovat
        </v-btn>
        <v-btn
            color="primary"
            outlined
            @click="closeDialog"
        >
          Zavřít
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "ReportDetails",
  props: ['openDialog','reportInfo', 'invoices'],
  data: () => ({
    dialog: false,
    report: {},
    valid: true,
    timeRules: [
      v => !!v || 'Čas je požadován!',
    ],
  }),
  methods: {

    closeDialog() {
      this.$emit('closeDialogChild')
    },

    updateReport() {
      this.$apollo.mutate({
        mutation: gql(`mutation {
          update_time_record (
            id: ` + this.reportInfo.id + `,
            start: "` + this.reportInfo.start + " " + this.reportInfo.startTime + `",
            end: "` + this.reportInfo.end + " " + this.reportInfo.endTime + `",
            invoice_id: ` + this.reportInfo.invoice + `,
          ) {
            id
          }
        }`
        )
      }).then(() => {
        this.$store.commit("REPORTS");
        this.openDialog = false;
      });
    }
  },
}
</script>

<style scoped>

</style>
