<template>
  <div>
    <h1 class="viewTitle">Vytvořit fakturu</h1>

    <div class="page-container">
      <main class="main-content">
        <div id="invoice-app">
          <div class="header">
            <div>
              <h1>Faktura</h1>
              <p v-if="!useFakturoid"  class="metaInvoice">Číslo faktury: <input placeholder="2021-0001" required type="text" class="variable-symbol" v-model="invoiceName"></p>
              <p  class="metaInvoice">Datum vystavení: <input type="date" v-model="issueDate"></p>
              <p  class="metaInvoice">Datum splatnosti: <input type="date" v-model="dueDate"></p>
            </div>
            <div style="max-width: 615px">
              <div class="section-spacer">
                <input type="text" placeholder="Název společnosti" class="company-name" v-model="company.name">
                <textarea v-model="company.contact"></textarea>
                <label for="currency-picker">Variabilní symbol:</label>
                <input type="number" class="variable-symbol" v-model="varSymbol">
                <label for="currency-picker">Bankovní účet:</label>
                <input type="text" class="variable-symbol" v-model="bankAccount">
                <label for="currency-picker">Způsob platby:</label>
                <select id="payment-method-picker" v-model="invoicePayment">
                  <option v-for="payment in payments" :value="payment" :key="payment.id">{{ payment.name }}</option>
                </select>
              </div>

              <div class="section-spacer">
                <p style="margin-bottom: 0"><strong>Odběratel:</strong></p>

                <v-row class="inovoiceSelectors">
                  <v-col v-if="contactList.workspace && !useFakturoid" cols="4" sm="4" md="4">
                    <div class="text-center">
                      <v-select
                        v-model="workspaceId"
                        :items="contactList.workspace"
                        item-text="contactInfo.company"
                        item-value="id"
                        label="Workspace"
                        v-on:change="contactInfo(1)"
                      ></v-select>
                    </div>
                  </v-col>
                  <v-col v-if="contactList.users && !useFakturoid" cols="4" sm="4" md="4">
                    <div class="text-center">
                      <v-select
                        v-model="userId"
                        :items="contactList.users"
                        item-text="contactInfo.company"
                        item-value="id"
                        label="Uživatel"
                        v-on:change="contactInfo(2)"
                      ></v-select>
                    </div>
                  </v-col>
                  <v-col v-if="contactList.clients && !useFakturoid" cols="4" sm="4" md="4">
                    <div class="text-center">
                      <v-select
                        v-model="clientId"
                        :items="contactList.clients"
                        item-text="contactInfo.company"
                        item-value="id"
                        label="Klient"
                        v-on:change="contactInfo(3)"
                      ></v-select>
                    </div>
                  </v-col>
                  <v-col v-if="contactList.fakturoid && useFakturoid" cols="6" sm="6" md="6">
                    <div class="text-center">
                      <v-select
                        v-model="fakturoidId"
                        :items="contactList.fakturoid"
                        item-text="contactInfo.company"
                        item-value="id"
                        label="Vyberte odběratele"
                        v-on:change="contactInfo(4)"
                      ></v-select>
                    </div>
                  </v-col>
                </v-row>

                    <textarea v-model="client"></textarea>
              </div>

            </div>
          </div>
          <div>
            <label style="display: block" for="currency-picker">Měna:</label>
            <select style="width: 170px" id="currency-picker" v-model="invoiceCurrency">
              <option v-for="currency in currencies" :value="currency" :key="currency.id">{{ currency.code }} - {{ currency.name }}</option>
            </select>
          </div>
          <table class="responsive-table">
            <thead>
            <tr>
              <th class="headQuantity">Počet</th>
              <th class="headName">Popis položky</th>
              <th class="headUnitPrice">Cena za MJ</th>
              <th class="headTax">DPH</th>
              <th class="headTotal">Celkem</th>
              <th></th>
            </tr>
            </thead>
            <tr v-for="(item, index) in items" :key="index">
              <td class="itemRow itemQuantity" data-label="Quantity"><input type="number" min="0" v-model="item.quantity" /></td>
              <td class="itemRow itemName" data-label="Item"><input type="text" v-model="item.description" /></td>
              <td class="itemRow itemUnitPrice" data-label="Price/unite"><div class="cell-with-input"><input type="number" min="0" v-model="item.price" />{{ invoiceCurrency.symbol }}</div></td>
              <td class="itemRow itemTax" data-label="Tax"><div class="cell-with-input"><input type="number" min="0" v-model="item.tax" />%</div></td>
              <td class="itemRow itemTotal" data-label="Total">{{ decimalDigits(item.price * item.quantity) }}</td>
              <td class="itemRow text-right"><v-btn icon class="is-danger" @click="deleteItem(index)"><v-icon>mdi-trash-can-outline</v-icon></v-btn></td>
            </tr>
          </table>
          <button v-on:click="addNewItem">Přidat položku</button>
          <table>
            <tr>
              <td>Mezisoučet</td>
              <td>{{ decimalDigits(subTotal) }}</td>
            </tr>
            <tr>
              <td>Daň</td>
              <td>{{ decimalDigits(taxTotal) }}</td>
            </tr>
            <tr class="text-bold">
              <td>Celkem</td>
              <td>{{ decimalDigits(grandTotal) }}</td>
            </tr>
          </table>
          <div class="section-spacer">
            <p>Poznámky:</p>
            <textarea style="min-height: 50px" @keyup="adjustTextAreaHeight"></textarea>
          </div>

          <button style="margin-right: 10px" @click="printInvoice">Vytisknout fakturu</button>
          <button v-if="!useFakturoid" @click="saveInvoice">Uložit</button>
          <button v-else @click="saveInvoice">Uložit do Fakturoidu</button>
        </div>

      </main>
    </div>

    <v-snackbar
        v-model="snackbarAdd"
        color="success"
    >
      Faktura úspěšně vytvořena

      <template v-slot:action="{ attrs }">
        <v-btn
            style="background-color: transparent"
            text
            v-bind="attrs"
            @click="snackbarAdd = false"
        >
          Zavřít
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
        v-model="snackbarUpdate"
        color="success"
    >
      Faktura úspěšně aktualizována

      <template v-slot:action="{ attrs }">
        <v-btn
            style="background-color: transparent"
            text
            v-bind="attrs"
            @click="snackbarUpdate = false"
        >
          Zavřít
        </v-btn>
      </template>
    </v-snackbar>

  </div>

</template>

<script>
import axios from "axios";


export default {
  name: "Invoices",

  data() {
    //var currenciesData=[{"id":1,"symbol":"Kč","name":"Česká koruna","symbol_native":"Kč","decimal_digits":2,"rounding":0,"code":"CZK","name_plural":"České koruny"}, {"id":2,"symbol":"€","name":"Euro","symbol_native":"€","decimal_digits":2,"rounding":0,"code":"EUR","name_plural":"euros"}, {"id":3,"symbol":"$","name":"US Dollar","symbol_native":"$","decimal_digits":2,"rounding":0,"code":"USD","name_plural":"US dollars"}];
    //var paymentMethods=[{"id":1,"name":"Bankovním převodem"}, {"id":2,"name":"Hotovost"}];

    return {
      snackbarUpdate: false,
      snackbarAdd: false,
      reports_id: [],
      tasks_id: [],
      invoice_id: null,
      fakturoid_invoice_id: null,
      new_fakturoid_invoice_id: null,
      invoiceName: "",
      useFakturoid: false,
      workspaceId: null,
      clientId: null,
      userId: null,
      fakturoidId: null,
      invoiceCurrency: {
          symbol: ''
      },
      invoicePayment: null,
      //taxRate: 21,
      varSymbol: '20210001',
      bankAccount: '',
      discountRate: 0,
      hour_rate: 0,
      items: [{
        description: 'Název položky',
        quantity: 0,
        tax: 0,
        price: 0,
        fakturoid_id: null
      }],
      fakturoid_items: [],
      currencies: null,
      payments: null,
      company: {
        name: 'Název společnosti',
        contact: 'Adresa\nTelefon\nE-mail'
      },
      client: 'Fakturační údaje odběratele',
      issueDate: '',
      dueDate: '',
      contactList: {
          workspace: null,
          users: null,
          clients: null,
          fakturoid: null
      },
    }
  },

  watch: {
    invoice_id: function () {
      this.saveFakturoidInvoiceId();
    },
    new_fakturoid_invoice_id: function () {
      this.saveFakturoidInvoiceId();
    },
  },

  methods: {
    addNewItem: function() {
      this.items.push(
          { description: 'Název položky', quantity: 0, price: 0, fakturoid_id: null }
      )
    },

    deleteItem: function(index) {
      this.items.splice(index, 1)
    },

    decimalDigits: function(value) {
      return value.toFixed(this.invoiceCurrency.decimal_digits) + ' ' + this.invoiceCurrency.symbol;
    },

    printInvoice: function() {
      this.saveInvoiceLocal();
      window.print();
    },

    saveInvoice: function() {

      if (!this.invoiceName && !this.useFakturoid) {
        alert("Je potřeba vyplnit číslo faktury!")
      } else {
        this.saveInvoiceLocal();
      }

      if(this.useFakturoid) {
        if(!this.fakturoid_invoice_id) {
          this.saveInvoiceFakturoid();
        } else  {
          this.updateInvoiceFakturoid();
        }
      }
    },

    adjustTextAreaHeight: function(event){
      var el = event.target;
      el.style.height = "1px";
      el.style.height = (25+el.scrollHeight)+"px";
    },

    userInfo() {
      // User info
      axios.get('https://dev.api.project.devg.cz/api/user')
      .then(response => {
        let user = response.data;

        // Use Fakturoid data if available
        if(user.fakturoid_account) {
          axios.get('https://dev.api.project.devg.cz/api/fakturoid/account')
          .then(response => {
            let fUser = response.data;
            fUser['hour_rate'] = user['hour_rate'];
            this.useFakturoid = true;
            this.parseUserInfo(fUser);
            this.makeItems();
          });
        } else {
          this.parseUserInfo(user);
          this.makeItems();
        }
      });

      // Setup dates
      this.issueDate = new Date().toISOString().substr(0, 10);
      let due = new Date();
      due.setDate(due.getDate() + 14);
      this.dueDate = due.toISOString().substr(0, 10);
    },

    parseUserInfo(user) {
        // Company / name
        if (user.company) {
            this.company.name = user.company;
        } else {
            this.company.name = user.name ? (user.name + ' ') : '';
            this.company.name += user.surname ? user.surname : '';
        }

        // Other info
        this.company.contact = user.address ? user.address : '';
        this.company.contact = this.company.contact.replaceAll(',', '\n');
        this.company.contact += '\n\n';
        if(user.ICO) {
            this.company.contact += 'IČ ' + user.ICO + '\n';
        }
        if(user.DIC) {
            this.company.contact += 'DIČ ' + user.DIC + '\n';
        }
        if(user.platce_DPH === 0) {
            this.company.contact += 'Neplátce DPH\n';
        }

        this.bankAccount = user.bank_account;

        // To delete
        /*if(user.platce_DPH) {
            this.taxRate = 21;
        } else {
            this.taxRate = 0;
        }*/

        this.hour_rate = user.hour_rate;

        axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
            'workspaces(id: ' + user.active_workspace_id + '){' +
            'data{' +
            'id,' +
            'contactInfo{' +
            'company' +
            '}' +
            '}' +
            '}' +
            '}'
        ).then(response => {
            this.contactList.workspace = response.data.data.workspaces.data;
        });
    },

    getContactList() {
      // Users
      axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
        'users{' +
          'data{' +
            'id,' +
            'contactInfo{' +
              'name,' +
              'surname,' +
              'company,' +
              'address,' +
              'ico,' +
              'dic' +
            '}' +
          '}' +
        '}' +
        '}'
      ).then(response => {
        this.contactList.users = response.data.data.users.data;
      });

      // Clients
      axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
        'clients{' +
          'data{' +
            'id,' +
            'contactInfo{' +
              'name,' +
              'surname,' +
              'company,' +
              'address,' +
              'ico,' +
              'dic' +
            '}' +
          '}' +
        '}' +
        '}'
      ).then(response => {
        this.contactList.clients = response.data.data.clients.data;
        if(this.clientId) {
          this.contactInfo(3);
        }
      });

      // Fakturoid Contacts
      axios.get('https://dev.api.project.devg.cz/api/fakturoid/contacts')
      .then(response => {
        console.log(response);
        this.contactList.fakturoid = response.data;
        console.log("Facturoid contacts");
        console.log(this.contactList.fakturoid);
      });
    },

    selectorData() {
      axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
        'payment_types {' +
          'data {' +
            'id,' +
            'name,' +
          '}' +
        '}' +
        '}'
      ).then(response => {
          this.payments = response.data.data.payment_types.data;
          this.invoicePayment = this.payments[0];
      });

      axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
        'currency_types {' +
          'data {' +
            'id,' +
            'symbol,' +
            'name,' +
            'symbol_native,' +
            'decimal_digits,' +
            'rounding,' +
            'code,' +
            'name_plural' +
          '}' +
        '}' +
        '}'
      ).then(response => {
          this.currencies = response.data.data.currency_types.data;
          this.invoiceCurrency = this.currencies[0];
      });
    },

    makeItems() {
      // Generate items from time reports
      if(this.invoice_id) {
        this.getItemsForInvoice();

      // Get item by invoice_id
      } else if(this.reports_id && this.reports_id.length > 0) {
        this.makeItemsFromReports();

      // Generate items from tasks
      } else if(this.tasks_id && this.tasks_id.length > 0) {
        this.makeItemsFromTasks();
      }
    },

    makeItemsFromReports() {
        if(!Array.isArray(this.reports_id)) {
            this.reports_id = [this.reports_id];
        }
        axios.post('https://dev.api.project.devg.cz/api/generateRecordsItems', {
            recordIds: this.reports_id,
            unitPrice: this.hour_rate
        }).then(response => {
            this.items = response.data;

            for (let i = 0; i < this.items.length; i++) {
              this.items[i].quantity = this.items[i].quantity.toFixed(1);
            }

        });
    },

    makeItemsFromTasks() {
        if(!Array.isArray(this.tasks_id)) {
            this.tasks_id = [this.tasks_id];
        }
        axios.post('https://dev.api.project.devg.cz/api/generateTasksItems', {
            taskIds: this.tasks_id,
            unitPrice: this.hour_rate
        }).then(response => {
            console.log("items:");
            console.log(response.data);
            this.items = response.data
        });
    },

    getItemsForInvoice() {
      axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
        'invoices(id:' + this.invoice_id + ') {' +
          'data {' +
            'name,' +
            'variable_symbol,' +
            'issue_date,' +
            'due_date,' +
            'price,' +
            'total_price,' +
            'fakturoid_id,' +
            'fakturoid_contact_id,' +
            'paymentType{' +
              'id,' +
              'name' +
            '}' +
            'currencyType{' +
              'id,' +
              'symbol,' +
              'name,' +
              'symbol_native,' +
              'decimal_digits,' +
              'rounding,' +
              'code,' +
              'name_plural' +
            '}' +
            'client{' +
              'id,' +
            '}' +
            'user{' +
              'id,' +
            '}' +
            'workspace{' +
              'id,' +
            '}' +
            'items{' +
              'id,' +
              'content,' +
              'unit_price,' +
              'tax,' +
              'quantity,' +
              'fakturoid_id' +
            '}' +
          '}' +
        '}' +
        '}'
      ).then(response => {
          console.log("SAVED INVOICE");
          console.log(response);
        let invoice = response.data.data.invoices.data[0];
        this.invoiceName = invoice.name;
        this.varSymbol = invoice.variable_symbol;
        this.issueDate = invoice.issue_date;
        this.dueDate = invoice.due_date;
        this.invoicePayment = invoice.paymentType;
        this.invoiceCurrency = invoice.currencyType;
        this.fakturoid_invoice_id = invoice.fakturoid_id;

        if(invoice.client) {
          this.clientId = invoice.client.id;
          this.contactInfo(3);
        } else if(invoice.user) {
          this.userId = invoice.user.id;
          this.contactInfo(2);
        } else if(invoice.workspace) {
          this.workspaceId = invoice.workspace.id;
          this.contactInfo(1);
        } else if(invoice.fakturoid_contact_id) {
          this.fakturoidId = invoice.fakturoid_contact_id;
          this.contactInfo(4);
        }

        if(invoice.items) {
          this.items = [];
          invoice.items.forEach(item => {
            let subItem = [];
            subItem['id'] = item.id;
            subItem['description'] = item.content;
            subItem['quantity'] = item.quantity;
            subItem['tax'] = item.tax;
            subItem['price'] = item.unit_price;
            subItem['fakturoid_id'] = item.fakturoid_id;
            this.items.push(subItem);
          });
        }
      });
    },

    contactInfo(type) {
        // Get contact info & reset other selectors
        let item = null;
        if (type === 1) {
            this.userId = null; this.clientId = null; this.fakturoidId = null;
            item = this.contactList.workspace.filter(obj => {
                return obj.id === this.workspaceId
            });
        } else if (type === 2) {
            this.workspaceId = null; this.clientId = null; this.fakturoidId = null;
            item = this.contactList.users.filter(obj => {
                return obj.id === this.userId
            });
        } else if (type === 3) {
            this.workspaceId = null; this.userId = null; this.fakturoidId = null;
            item = this.contactList.clients.filter(obj => {
                return obj.id === this.clientId
            });
        } else  if (type === 4) {
            this.workspaceId = null; this.userId = null; this.clientId = null;
            item = this.contactList.fakturoid.filter(obj => {
                return obj.id === this.fakturoidId
            });
        } else  {
            return;
        }

        let contactInfo = item[0].contactInfo;

        if(contactInfo.company) {
            this.client = contactInfo.company + '\n';
        } else {
            this.client = contactInfo.name ? contactInfo.name : '';
            this.client += " ";
            this.client += contactInfo.surname ? contactInfo.surname : '';
            this.client += "\n";
        }
        if(contactInfo.address) {
            this.client += contactInfo.address.replaceAll(',', '\n');
            this.client += '\n\n';
        }
        if(contactInfo.ico) {
            this.client += 'IČ: ' + contactInfo.ico + '\n';
        }
        if(contactInfo.dic) {
            this.client += 'DIČ: ' + contactInfo.dic + '\n';
        }
    },

    saveInvoiceLocal() {
      // ----- Collect data for invoice -----
      let argumentString = '';
      argumentString += 'name: "' + this.invoiceName + '", ';
      argumentString += 'variable_symbol: "' + this.varSymbol + '", ';
      argumentString += 'issue_date: "' + this.issueDate + '", ';
      argumentString += 'due_date: "' + this.dueDate + '", ';
      argumentString += 'payment_type_id: ' + this.invoicePayment.id + ', ';
      argumentString += 'currency_id: ' + this.invoiceCurrency.id + ', ';
      //argumentString += 'tax: ' + this.taxRate + ', ';
      argumentString += 'price: ' + this.subTotal + ', ';
      argumentString += 'total_price: ' + this.grandTotal + ', ';

      if(this.userId) {
        argumentString += 'user_id: ' + this.userId + ', ';
      } else if(this.clientId) {
          argumentString += 'client_id: ' + this.clientId + ', ';
      } else if(this.workspaceId) {
          argumentString += 'workspace_id: ' + this.workspaceId + ', ';
      } else if(this.fakturoidId) {
          argumentString += 'fakturoid_contact_id: ' + this.fakturoidId + ', ';
      }

      if(!this.invoice_id) {
          if(this.reports_id && this.reports_id.length > 0) {
              argumentString += 'time_records: [' + this.reports_id + '], ';
          } else if(this.tasks_id && this.tasks_id.length > 0) {
              argumentString += 'tasks: [' + this.tasks_id + '], ';
          }
      }


      // ----- Create new invoice -----
      if (!this.invoice_id) {
        axios.get('https://dev.api.project.devg.cz/graphql/query?query=mutation{' +
         'new_invoice(' + argumentString + '){' +
             'id' +
         '}' +
         '}'
        ).then(response => {
          console.log(response);
          this.invoice_id = response.data.data.new_invoice.id;
          this.saveInvoiceItems();
          this.snackbarAdd = true;
        });


      // ----- Update existing invoice -----
      } else {
        argumentString += 'id: ' + this.invoice_id + ', ';
        axios.get('https://dev.api.project.devg.cz/graphql/query?query=mutation{' +
          'update_invoice(' + argumentString + '){' +
            'id' +
          '}' +
          '}'
        ).then(response => {
            console.log(response);
            this.updateInvoiceItems();
            this.snackbarUpdate = true;
        });
      }

    },

    saveInvoiceFakturoid() {
      let paymentType = 'bank';
      if(this.invoicePayment.id === 2) {
        paymentType = 'cash';
      }

      let lines = [];

      this.items.forEach( item => {
        let line = {
          'name': item['description'],
          'quantity': item['quantity'],
          'vat_rate': item['tax'],
          'unit_price': item['price']
        };
        lines.push(line);
      });

      console.log(lines);

      axios.post('https://dev.api.project.devg.cz/api/fakturoid/newInvoice', {
        subject_id: this.fakturoidId,
        number: this.invoiceName,
        variable_symbol: this.varSymbol,
        issued_on: this.issueDate,
        due_on: this.dueDate,
        payment_method: paymentType,
        currency: this.invoiceCurrency.code,
        note: this.note,
        lines: lines,
      }).then(response => {
          console.log("Fakturoid Response");
          console.log(response);
          this.invoiceName = response.data.number;
          this.fakturoid_invoice_id = response.data.id;
          this.new_fakturoid_invoice_id = response.data.id;
          for (let i = 0; i < response.data.lines.length; i++) {
            this.items[i]['fakturoid_id'] = response.data.lines[i]['id'];
          }
          this.fakturoid_items = response.data.lines;
          this.saveFakturoidItemId();
          this.snackbarAdd = true;
      });
    },

    updateInvoiceFakturoid() {
      let paymentType = 'bank';
      if(this.invoicePayment.id === 2) {
        paymentType = 'cash';
      }

      let lines = [];

      this.items.forEach( item => {
        let line = {
          'id': item['fakturoid_id'],
          'name': item['description'],
          'quantity': item['quantity'],
          'vat_rate': item['tax'],
          'unit_price': item['price']
        };
        lines.push(line);
      });

      console.log(lines);

      axios.post('https://dev.api.project.devg.cz/api/fakturoid/updateInvoice', {
        invoiceId: this.fakturoid_invoice_id,
        subject_id: this.fakturoidId,
        number: this.invoiceName,
        variable_symbol: this.varSymbol,
        issued_on: this.issueDate,
        due_on: this.dueDate,
        payment_method: paymentType,
        currency: this.invoiceCurrency.code,
        note: this.note,
        lines: lines,
      }).then(response => {
        console.log("Fakturoid Response");
        console.log(response);
        this.snackbarUpdate = true;
      });
    },

    saveInvoiceItems() {
      // ----- Save invoice items -----
      for(let i = 0; i < this.items.length; i++) {
        let item = this.items[i];
        let itemArgString = '';
        itemArgString += 'content: "' + item.description + '", ';
        itemArgString += 'unit_price: ' + item.price + ', ';
        itemArgString += 'quantity: ' + item.quantity + ', ';
        itemArgString += 'tax: ' + item.tax + ', ';
        itemArgString += 'price: ' + item.price * item.quantity + ', ';
        itemArgString += 'invoice_id: ' + this.invoice_id + ', ';

        axios.get('https://dev.api.project.devg.cz/graphql/query?query=mutation{' +
            'new_invoice_item(' + itemArgString + '){' +
            'id' +
            '}' +
            '}'
        ).then(response => {
            this.items[i].id = response.data.data.new_invoice_item.id;
            this.saveFakturoidItemId();
        });
      }
    },

    updateInvoiceItems() {
      this.items.forEach( item => {
        let itemArgString = '';
        itemArgString += 'id: ' + item.id + ', ';
        itemArgString += 'content: "' + item.description + '", ';
        itemArgString += 'unit_price: ' + item.price + ', ';
        itemArgString += 'quantity: ' + item.quantity + ', ';
        itemArgString += 'price: ' + item.price * item.quantity + ', ';

        axios.get('https://dev.api.project.devg.cz/graphql/query?query=mutation{' +
          'update_invoice_item(' + itemArgString + '){' +
            'id' +
          '}' +
          '}'
        ).then(response => {
          console.log(response);
        });
      });
    },

    saveFakturoidInvoiceId() {
      if(this.invoice_id && this.new_fakturoid_invoice_id) {
        axios.get('https://dev.api.project.devg.cz/graphql/query?query=mutation{' +
          'update_invoice(id: ' + this.invoice_id + ', name: "' + this.invoiceName + '", fakturoid_id: ' + this.new_fakturoid_invoice_id + '){' +
            'id' +
          '}' +
          '}'
        )
      }
    },

    saveFakturoidItemId() {
        console.log("Item id update");
        console.log(this.items);
        console.log(this.fakturoid_items);
      if(this.items.length && this.fakturoid_items.length) {
          console.log("Itemy jsou stejne dlouhe");
        this.items.forEach(item => {
         if(!item.id) {
             return;
         }
          console.log("Probiram item " + item.id + item.description + item.tax);
          axios.get('https://dev.api.project.devg.cz/graphql/query?query=mutation{' +
            'update_invoice_item(id: ' + item.id + ', fakturoid_id: ' + item.fakturoid_id + '){' +
              'id' +
            '}' +
            '}'
          ).then(response => {
              console.log(response);
          });
        });
      }
    },

  },

  computed: {

    subTotal: function() {
        return this.items.reduce(function(accumulator, item) {
        return accumulator + (item.price * item.quantity);
      }, 0);
    },

    taxTotal: function() {
        return this.items.reduce(function(accumulator, item) {
            return accumulator + (item.price * item.quantity * (item.tax / 100));
        }, 0);
    },

    grandTotal: function() {
        return this.subTotal + this.taxTotal;
    },

    getUser() {
        return this.$store.state.user
    },
  },

  created() {
    this.$store.dispatch('getUser');

    if(this.$route.query.reports) {
      this.reports_id = this.$route.query.reports;
    }

    if(this.$route.query.tasksId) {
      this.tasks_id = this.$route.query.tasksId;
    }

    if(this.$route.query.invoiceId) {
      this.invoice_id = this.$route.query.invoiceId;
    }

    this.getContactList();
    this.selectorData();
    this.userInfo();
  },
}

</script>


<style scoped>

body {
  background: linear-gradient(to right, #ff5f6d, #ffc371);
}
.main-content {
  min-height: 100vh;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

#invoice-app {
  background-color: #faebd7;
  padding: 2rem;
  border-radius: 0.5rem;
}
@media (min-width: 761px) {
  .header {
    display: flex;
  }
}
@media (min-width: 761px) {
  .header > div:nth-child(-n+1) {
    order: 1;
    flex: 1;
    text-align: right;
    padding-left: 1rem;
  }
}
.section-spacer {
  margin: 1rem 0;
}
input, select, textarea {
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  display: inline-block;
  transition: background-color 0.3s ease-in-out;
  width: 100%;
}
input:focus, select:focus, textarea:focus {
  outline-color: #ffc371;
  background-color: rgba(255, 255, 255, 0.6);
}
input:hover, select:hover, textarea:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

@media only screen and (min-width: 761px) {
  input, select, textarea {
    width: 100%;
  }
}
textarea {
  width: 100%;
  min-height: 150px;
}
@media only screen and (max-width: 760px) {
  select {
    width: 100%;
  }
}
@media print {
  button {
    display: none;
  }

  .v-navigation-drawer__content, .theme--light.v-navigation-drawer, header {
    display: none !important;
  }

  select {
    appearance: none;
  }

  input, select, textarea {
    background-color: transparent;
  }

  .main-content {
    align-items: flex-start;
  }
}
.company-name {
  font-size: 2rem;
}

.variable-symbol {
  margin-bottom: 10px;
}
table {
  width: auto;
  border-collapse: collapse;
  margin: 2rem 0;
}
table thead th {
  padding: 5px;
  text-align: left;
}
table thead th:nth-child(-n+1) {
  padding-left: 0;
}
table thead th:nth-last-child(-n+1) {
  padding-right: 0;
}
table tr {
  border-bottom: 1px solid #f4d4aa;
}
table tr td {
  padding: 5px;
}
table tr td:nth-child(-n+1) {
  padding-left: 0;
}
table tr td:nth-last-child(-n+1) {
  padding-right: 0;
}
table tr td input {
  width: 100%;
}
table tr td .cell-with-input {
  display: flex;
}
table tr td .cell-with-input input {
  margin: 0 0.2rem;
}
.responsive-table {
  width: 100%;
}
@media only screen and (max-width: 760px) {
  .responsive-table table, .responsive-table thead, .responsive-table tbody, .responsive-table th, .responsive-table td, .responsive-table tr {
    display: block;
  }
  .responsive-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .responsive-table tr {
    padding: 2rem 0;
  }
  .responsive-table td[data-label] {
    position: relative;
    padding-left: 40%;
    display: flex;
    align-items: center;
  }
  .responsive-table td[data-label]:before {
    font-family: "AkkuratLL-Bold",sans-serif !important;
    content: attr(data-label);
    position: absolute;
    top: 0.5rem;
    left: 0;
    width: 35%;
    padding-right: 10px;
    white-space: nowrap;
    font-weight: bold;
  }
}
button {
  background-color: #81cf71;
  border: none;
  border-radius: 100px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}
button:focus {
  outline-color: #ffc371;
  background-color: #69c656;
}
button:hover {
  background-color: #70c95e;
}
@media print {
  button {
    display: none;
  }

  .v-navigation-drawer__content, .theme--light.v-navigation-drawer, header {
    display: none !important;
  }

}
button.is-danger {
  margin-bottom: 0;
  background-color: #ff5f6d;
}
button.is-danger:focus {
  background-color: #ff3b4c;
}
button.is-danger:hover {
  background-color: #ff4656;
}
.text-right {
  text-align: right;
}
.text-bold {
  font-weight: bold;
}

.itemTax {
  width: 75px;
}

.itemTax input {
  text-align: right;
}

.itemQuantity {
  width: 60px
}

.itemQuantity input {
  text-align: center;
}

.itemUnitPrice {
  width: 100px;
}

.itemUnitPrice input {
  text-align: right;
}

.itemName {
  width: 545px;
}

.itemTotal {
  text-align: right;
  width: 100px;
}

.headUnitPrice, .headTax, .headTotal {
  text-align: right;
}

.metaInvoice {
  width: 160px;
  margin-left: auto;
}

</style>
