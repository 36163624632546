import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    topNav: 0,
    tasks: 0,
    leftNav: 0,
    projects: 0,
    workspace: 0,
    profile: 0,
    reports: 0,
    clients: 0,
    date: 0,
    isRunning: false,
    user: {},
  },
  mutations: {

    TOPNAV: (state) => state.topNav++,
    TASKS: (state) => state.tasks++,
    LNAV: (state) => state.leftNav++,
    PROJ: (state) => state.projects++,
    WORK: (state) => state.workspace++,
    PROF: (state) => state.profile++,
    REPORTS: (state) => state.reports++,
    CLIENTS: (state) => state.clients++,
    DATE: (state) => state.date++,

    /*changeRunningState(state, status) {
      state.isRunning = status
    },*/

    GET_NAME(state, name) {
      state.user = name
    }
  },
  actions: {
    getUser({ commit }) {
      axios.get('https://dev.api.project.devg.cz/api/user').then(response => {
        commit('GET_NAME', response.data)
      })

    },
  },
  modules: {}
});
