<template>
  <v-dialog
      v-model="openDialog"
      persistent
      max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Upravit klienta</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
          >
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    label="Jméno*"
                    v-model="clientInfo.contactInfo.name"
                    :counter="40"
                    required
                    :rules="rules"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    label="Příjmení*"
                    v-model="clientInfo.contactInfo.surname"
                    :counter="40"
                    persistent-hint
                    required
                    :rules="rules"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    label="Společnost*"
                    v-model="clientInfo.contactInfo.company"
                    :counter="30"
                    required
                    :rules="rules"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    label="Adresa"
                    v-model="clientInfo.contactInfo.address"
                    :counter="50"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    label="IČO"
                    v-model="clientInfo.contactInfo.ico"
                    :counter="9"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    label="DIČ"
                    v-model="clientInfo.contactInfo.dic"
                    :counter="11"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    label="Bank account"
                    v-model="clientInfo.contactInfo.bank_account"
                    :counter="30"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    label="Hodinová sazba"
                    v-model="clientInfo.contactInfo.hour_rate"
                    :counter="10"
                    type="number"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-select
                    :items="platceDPH"
                    :item-text="'name'"
                    :item-value="'value'"
                    v-model="clientInfo.contactInfo.platce_DPH"
                    name="platceDPH"
                    label="Jsem plátce DPH:"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <small>*požadováno</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            :disabled="!valid"
            color="primary"
            @click="updateClient()"
        >
          Aktualizovat
        </v-btn>
        <v-btn
            color="primary"
            outlined
            @click="closeDialog()"
        >
          Zavřít
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
        v-model="snackbar"
        color="success"
    >
      Klient úspěšně aktualizován

      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Zavřít
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "ClientDetails",
  props: ['openDialog','clientInfo'],
  data: () => ({
    snackbar: false,
    dialog: false,
    client: {},
    valid: true,
    platceDPH: [
      { name: 'Ne', value: 0 },
      { name: 'Ano', value: 1 }
    ],
    selectedPlatceDPH: 0,
    rules: [
      value => !!value || 'Požadováno',
      value => (value && value.length >= 1) || 'Minimálně 1 znak',
    ],
  }),
  methods: {

    closeDialog() {
      this.$emit('closeDialogChild')
    },

    openSnackbarUpdated() {
      this.$emit('openSnackbarUpdated')
    },

    updateClient() {

      console.log("DEATAIL CLIENT: " + this.clientInfo.contactInfo.platce_DPH)

      this.$apollo.mutate({
        mutation: gql(`mutation {
          update_client (
            id: ` + this.clientInfo.id + `,
            name: "` + this.clientInfo.contactInfo.name +`",
            surname: "` + this.clientInfo.contactInfo.surname + `",
            company: "` + this.clientInfo.contactInfo.company + `",
            address: "` + this.clientInfo.contactInfo.address + `",
            ICO: "` + this.clientInfo.contactInfo.ico + `",
            DIC: "` + this.clientInfo.contactInfo.dic + `",
            bank_account: "` + this.clientInfo.contactInfo.bank_account + `",
            hour_rate: ` + this.clientInfo.contactInfo.hour_rate + `,
            platce_DPH: ` + this.clientInfo.contactInfo.platce_DPH + `
          ) {
            id
          }
        }`
        )
      }).then(() => {
        this.openDialog = false;
        this.openSnackbarUpdated();
      });

    }
  }
}
</script>

<style scoped>

</style>
