<template>
  <v-row justify="space-around" v-if="projectAdmin">
    <v-col cols="auto">
      <v-dialog
          transition="dialog-bottom-transition"
          max-width="600"
          v-model="dialog"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              style="z-index: 0; margin-bottom: 7px"
              v-bind="attrs"
              v-on="on"
              icon
          >
            <v-icon>mdi-cog-outline</v-icon>
            </v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar
                color="primary"
                dark
            >Nastavení projektu</v-toolbar>

            <v-card-text>
              <v-row v-if="project">

                <v-col cols="12">
                  <v-text-field
                          label="Název projektu*"
                          v-model="project.name"
                          required
                          :rules="rules"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-select
                          v-model="participans"
                          :items="members.data"
                          item-text="name"
                          item-value="id"
                          chips
                          attach
                          multiple
                          label="Uživatelé"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="4">
                  <v-select
                          v-model="client"
                          :items="clients.data"
                          item-text="contactInfo.company"
                          item-value="id"
                          label="Klient"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="4">
                  <v-select
                          :items="activeStatus"
                          :item-text="'name'"
                          :item-value="'value'"
                          v-model="project.active"
                          name="Active status"
                          label="Ukončit projekt?"
                  ></v-select>
                </v-col>

              </v-row>
            </v-card-text>

            <v-card-actions class="justify-end">
              <v-btn color="error" @click="deleteProject()">
                SMAZAT PROJEKT
              </v-btn>
              <v-btn color="primary" @click="updateProject()">
                AKTUALIZOVAT
              </v-btn>
              <v-btn
                  color="primary"
                  outlined
                  @click="dialog.value = false"
              >

                Zavřít
              </v-btn>
            </v-card-actions>

          </v-card>
        </template>
      </v-dialog>
    </v-col>
    <v-snackbar
        v-model="snackbarDeleteMyself"
    >
      Nelze smazat sám sebe!

      <template v-slot:action="{ attrs }">
        <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbarDeleteMyself = false"
        >
          Zavřít
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import axios from "axios";

export default {
name: "ProjectSettings",
  props: ['projectAdmin'],
  data() {
    return {
      snackbarDeleteMyself: false,
      rules: [
        value => !!value || 'Required',
        value => (value && value.length >= 1) || 'Min 1 characters',
      ],
      dialog: false,
      participans: [],
      client: null,
      members: [],
      clients: [],
      project: [],
      activeStatus: [
        { name: 'Ano', value: false },
        { name: 'Ne', value: true }
      ],
    };
  },

  methods: {

    updateProjectData(project) {
      this.project = project;

      // Get array project users ids
      if(this.project.userProjects) {
        this.participans = [];
        this.project.userProjects.forEach(
          userProjects => this.participans.push(userProjects.user.id)
        );
      }

      if(this.project.client) {
          this.client = this.project.client.id;
      }
    },

    loadSelectorMenuItems() {
      // Users
      axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
        'users{' +
          'data{' +
            'id,' +
            'name,' +
          '}' +
        '}' +
        '}'
      ).then(response => {
        this.members = response.data.data.users;
        console.log(this.members);
      });

      // Clients
      axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
          'clients{' +
            'data{' +
              'id,' +
              'contactInfo{' +
                'company' +
              '}' +
            '}' +
          '}' +
          '}'
      ).then(response => {
          this.clients = response.data.data.clients;
      });
    },

    updateProject() {

      //if (this.participans.includes(this.project.creator.id)) {

        this.$apollo.mutate({
          mutation: gql(`mutation {
            update_project (
              id: ` + this.project.id + `,
              client_id: ` + this.client + `,
              name: "` + this.project.name + `",
              users: [` + this.participans + `],
              active: ` + this.project.active + `
            ) {
              id
              }
            }`
          )
        }).then(() => {
          this.$store.commit("LNAV");
          this.$store.commit("CLIENTS");
          this.$parent.loadProject();
        });

        this.dialog = false;

      //} else {
      //  this.snackbarDeleteMyself = true;
      //}

    },

    deleteProject() {

      this.$confirm(
          "Opravdu si přejete tuto položku smazat ?",
          { color: "warning", title: "Upozornění", buttonTrueText: "Ano", buttonFalseText: "Ne", icon: "mdi-alert"  }
      ).then(res => {

        if (res) {
          this.$apollo.mutate({
            mutation: gql(`mutation {
              delete_project (id: ` + this.project.id + `) {
                id
                }
              }`
            )
          }).then(() => {
            this.$store.commit("LNAV");
            this.$store.commit("CLIENTS");
            this.$router.push({ name: 'Dashboard' })
          });

        }

      });
    }

  },
  created () {
    this.$store.dispatch('getUser');
    this.loadSelectorMenuItems();
  },
}
</script>

<style scoped>

.userBadge {
  font-family: "AkkuratLL-Bold",sans-serif !important;
  display: inline-block;
  padding: 2px 15px 2px 15px;
  background-color: #1976d2;
  border-radius: 25px;
  color: #ffffff;
  font-weight: bold;
  margin-right: 5px;
  margin-left: 5px;
}

.userBadge button {
  margin: 0;
  height: auto !important;
}

.usersCont {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
}

</style>
