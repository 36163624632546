<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          class="addBtn"
          height="40"
          width="40"
          depressed
          min-width="40"
          color="#3D70B2"
          v-bind="attrs"
          v-on="on"
      >
        +
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Přidat nového klienta</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
          >
          <v-row>
            <v-col
                cols="12"
                sm="6"
                md="4"
            >
              <v-text-field
                  label="Jméno*"
                  v-model="name"
                  :counter="40"
                  required
                  :rules="rules"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="4"
            >
              <v-text-field
                  label="Příjmení*"
                  v-model="surname"
                  :counter="40"
                  persistent-hint
                  required
                  :rules="rules"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="4"
            >
              <v-text-field
                  label="Společnost*"
                  v-model="company"
                  :counter="30"
                  required
                  :rules="rules"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="4"
            >
              <v-text-field
                  label="Adresa"
                  v-model="address"
                  :counter="50"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="4"
            >
              <v-text-field
                  label="IČO"
                  v-model="ico"
                  :counter="9"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="4"
            >
              <v-text-field
                  label="DIČ"
                  v-model="dic"
                  :counter="11"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="4"
            >
              <v-text-field
                  label="Bank account"
                  v-model="bankacc"
                  :counter="30"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="4"
            >
              <v-text-field
                  label="Hodinová sazba"
                  v-model="hrate"
                  :counter="10"
                  type="number"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                md="4"
            >
              <v-select
                  :items="platceDPH"
                  :item-text="'name'"
                  :item-value="'value'"
                  v-model="selectedPlatceDPH"
                  name="platceDPH"
                  label="Jsem plátce DPH:"
              />
            </v-col>
          </v-row>
          </v-form>
        </v-container>
        <small>*požadováno</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            :disabled="!valid"
            color="primary"
            @click="addClient()"
        >
          Uložit
        </v-btn>
        <v-btn
            color="priamry"
            outlined
            @click="dialog = false"
        >
          Zavřít
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import gql from "graphql-tag";
import axios from "axios";

const GET_PROJECTS = gql`
  query getProjects {
    projects {
      data {
        id
        name
      }
    }
  }
`;

export default {
name: "AddClient",
  data: () => ({
    snackbar: false,
    valid: true,
    dialog: false,
    name: '',
    surname: '',
    company: '',
    address: '',
    ico: '',
    dic: '',
    hrate: '',
    bankacc: '',
    platceDPH: [
      { name: 'Ne', value: 0 },
      { name: 'Ano', value: 1 }
    ],
    selectedPlatceDPH: 0,
    rules: [
        value => !!value || 'Required',
        value => (value && value.length >= 3) || 'Min 3 characters',
    ],
  }),
  apollo: {
    projects: {
      query: GET_PROJECTS
    }
  },
  methods:  {
    addClient() {

      if(this.$refs.form.validate()) {
        console.log("CHILD: " + this.name, this.surname, this.company, this.address, this.ico, this.dic, this.hrate, this.bankacc, this.selectedPlatceDPH)
        this.$emit('addClientChild', this.name, this.surname, this.company, this.address, this.ico, this.dic, this.hrate, this.bankacc, this.selectedPlatceDPH)
        this.dialog = false
      } else {
        return false
      }

    },

    loadSelectorMenuItems() {
      // Projects
      axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
          'projects{' +
          'data{' +
          'id,' +
          'name,' +
          '}' +
          '}' +
          '}'
      ).then(response => {
        this.projects = response.data.data.projects;
        console.log(this.projects);
      });
    }

  },

  created() {
    this.loadSelectorMenuItems();
  }

}
</script>

<style scoped>

.addBtn {
  font-family: "AkkuratLL-Bold",sans-serif !important;
  z-index: 0;
  border: 1px solid #3D70B2 ;
  border-radius: 10px;
  color: #ffffff;
  background-color: #3D70B2;
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
  margin-top: 1%;
  margin-bottom: 2%;
}

</style>
