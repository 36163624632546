<template>
  <nav class="topNav">
    <v-toolbar-title style="width: 100%; padding-top: 5px">

      <div v-if="taskName" class="topRunningTask">
        <span class="taskName">{{ taskName }}</span>
        <span v-if="projectName" class="projectName">{{ projectName}}</span>
        <span class="time">{{ timeSpend }}</span>
        <span class="actionBtn">
          <v-btn v-if="timeSpend"
                 style="min-width: auto; margin-bottom: 0"
                 class="flexRight"
                 raised
                 depressed
                 icon
                 @click="timeRecordAction(taskId)"
          >
            <v-icon size="35px" style="margin-bottom: 0">
              mdi-stop-circle
            </v-icon>
          </v-btn>
<!--          <v-btn
                 style="min-width: auto; margin-bottom: 0"
                 class="flexRight"
                 raised
                 depressed
                 icon
                 @click="doneRecordAction(taskId)"
          >
            <v-icon size="35px" style="margin-bottom: 0">
              mdi-stop-circle
            </v-icon>
          </v-btn>-->
        </span>
      </div>
      <div v-else style="padding-top: 7px">
        <span style="color: #757575" class="taskName">Žádný běžící úkol</span>
      </div>

    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items class="hidden-xs-only">
      <v-btn
          to="/profile"
          raised
          depressed
          icon
          v-ripple="{class: `color-blue`}"
          class="btnLeftPanel loggedUser"
      >
        <div class="pa-3 primary rounded-circle d-inline-block white--text" style="width: 35px; height: 35px; font-size: 15px; line-height: 15px; padding: 10px !important;">
          {{ userName.charAt(0) }}
        </div>
        <span class="loggedUser">{{ userName }}</span>

      </v-btn>
      <v-btn
          @click="logout"
          raised
          depressed
          icon
          v-ripple="{class: `color-blue`}"
          class="btnLeftPanel"
      >
        <v-icon class="logoutIcon">mdi-logout</v-icon>
      </v-btn>
    </v-toolbar-items>
  </nav>
</template>

<script>
import axios from "axios";
import gql from "graphql-tag";

export default {
  name: "TopNavigation",
  data() {
    return {
      myprop: null,
      isPressedActive: false,
      appTitle: "Pro Track",
      sidebar: false,
      menuItems: [{ title: "Profile", path: "/profile" }],
      taskName: '',
      projectName: '',
      taskId: null,
      timeStart: 0,
      timeSpend: ''
    };
  },


  watch: {
    "$store.state.topNav": {
      handler: function() {
        this.getRunningRecord();
      },
      immediate: true // provides initial (not changed yet) state
    }
  },

  computed: {

    userName() {
      return this.$store.state.user.nickname
    }
  },

  created () {
    this.$store.dispatch('getUser');
    this.getRunningRecord();
    this.updateTime();

    /*this.$store.watch(
        (state)=>{
          return this.$store.state.isRunning
        },
        (newValue, oldValue)=>{
          //something changed do something
          console.log(oldValue)
          console.log(newValue)
        }
    )*/

  },

  methods: {

      getRunningRecord() {
          axios.get('https://dev.api.project.devg.cz/api/timeRecord').then(response => {
              this.taskName = response.data.name;
              this.projectName = response.data.project_name;
              this.taskId = response.data.task_id;
              this.timeStart = Date.parse(response.data.start);
        })
      },

      updateTime() {
          if(this.timeStart) {
              let timeSpend = new Date(Date.now() - this.timeStart);

              var sec_num = parseInt(timeSpend / 1000);
              var hours   = Math.floor(sec_num / 3600);
              var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
              var seconds = sec_num - (hours * 3600) - (minutes * 60);

              if (hours   < 10) {hours   = "0"+hours;}
              if (minutes < 10) {minutes = "0"+minutes;}
              if (seconds < 10) {seconds = "0"+seconds;}
              this.timeSpend =  hours+':'+minutes+':'+seconds;
          } else {
              this.timeSpend = "";
          }

          setTimeout(this.updateTime, 500);
      },

      timeRecordAction(taskId) {
          this.$apollo.mutate({
              mutation: gql(`mutation {
              time_record_action (task_id: ` + taskId + `) {
                id
                }
              }`
              ),
              refetchQueries: () => {this.getRunningRecord()}
          }).then(() => {
              this.$store.commit("TASKS");
              this.$store.commit("PROJ");
          });

      },

    doneRecordAction(taskId) {
      this.timeRecordAction(taskId);

      this.$apollo.mutate({
        mutation: gql(`mutation {
          update_task (
            id: ` + taskId + `,
            status_id: 3,
          ) {
            id
          }
          }`
        )
      }).then(() => {
          this.$store.commit("TASKS");
          this.$store.commit("PROJ");
      });

    },

    logout() {
      console.log("LOGOUT")
      let currentObj = this;
      axios.get('https://dev.api.project.devg.cz/api/logout', { withCredentials: true }).then(response => {
        window.location.href = "/login";
        currentObj.output = response.data;
      }).catch(error => {
        location.reload();
        currentObj.output = error;
      });
    }
  }
};
</script>

<style>

.loggedUser {
  width: auto !important;
  margin-left: 10px;
}

  header {
    background-color: #ffffff !important;
  }

  .logoutIcon {
    margin-left: 20px;
  }

  .topNav {
    display: flex;
    width: 100%;
  }

  .avatar {
    color: #ffffff;
  }

  .taskName {
    font-size: 18px;
  }

  .projectName {
    font-size: 13px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: rgba(25, 118, 210, 0.45);
    padding: 1px 10px 3px 10px;
    border-radius: 25px;
    top: -1px;
  }

  .time {
    font-family: "AkkuratLL-Bold",sans-serif !important;
    margin-right: 10px;
    font-weight: bold;
    font-size: 18px;
    width: 80px;
  }

@media only screen and (max-width: 900px) {

  .taskName {
    font-size: 15px;
    display: block;
    max-height: 40px;
    width: 100%;
  }

  .projectName {
    display: inline-block;
    margin-left: 0;
  }

  .time, .actionBtn {
    display: inline-block;
  }

  .topRunningTask {
    padding-top: 50px;
  }

}

</style>
