import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Clients from "@/views/Clients";
import UserProfile from "@/views/UserProfile";
import Login from "@/views/Login";
import Project from "@/views/Project";
import Reports from "@/views/Reports";
import Invoices from "@/views/Invoices";
import Register from "@/views/Register";
import InvoicesHistory from "@/views/InvoicesHistory";
import ReportsCompany from "@/views/ReportsCompany";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard
  },
  {
    path: "/clients",
    name: "Clients",
    component: Clients
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports
  },
  {
    path: "/invoices",
    name: "Invoices",
    component: Invoices
  },
  {
    path: "/invoices-history",
    name: "InvoicesHistory",
    component: InvoicesHistory
  },
  {
    path: "/invoices-company",
    name: "ReportsCompany",
    component: ReportsCompany
  },
  {
    path: "/profile",
    name: "Profile",
    component: UserProfile
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/register",
    name: "Register",
    component: Register
  },
  {
    path: "/project/:projectID",
    name: "Project",
    props(route) {
      return {
        projectID: route.params.projectID
      }
    },
    component: Project
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
