<template>
  <div style="padding: 0">
    <div>
      <v-dialog
          v-model="dialog"
          transition="dialog-bottom-transition"
          max-width="900"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="changeW"
              v-bind="attrs"
              v-on="on"
          >
            <v-icon left>mdi-view-dashboard</v-icon>
            <!--{{ getUser.active_workspace_name }}-->
            {{workspaceName}}
          </v-btn>

        </template>
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar
                color="primary"
                dark
            >Aktuální workspace: <b>{{ getUser.active_workspace_name }}</b></v-toolbar>

            <v-card-text>
              <v-col
                  cols="12"
                  class="py-2"
              >
                <h3 style="text-align: left; margin-bottom: 10px">Přepnout workspace:</h3>
              <v-btn v-for="workspace in workspaces.data" :key="workspace.id"
                  class="ma-2"
                  @click="changeWorkspace(workspace.id)"
              >
                <v-icon left>mdi-view-dashboard</v-icon>
                {{ workspace.name }}
              </v-btn>
              </v-col>
              <v-col
                  v-if="workspaceAdmin"
                  cols="12"
                  class="py-2"
              >
                <h3 style="text-align: left; margin-bottom: 10px">Informace o workspace:</h3>

                <v-form
                    v-model="valid"
                    ref="form"
                    lazy-validation
                >
                  <v-container>
                    <v-row>
                      <v-col
                          cols="12"
                          md="4"
                      >
                        <v-text-field
                            v-model="workspaceInfo.name"
                            :rules="nameRules"
                            :counter="20"
                            label="Název workspace"
                            required
                        ></v-text-field>

                      </v-col>
                      <v-col
                          cols="12"
                          md="4"
                      >
                        <v-text-field
                            v-model="workspaceInfo.contactInfo.company"
                            :counter="20"
                            label="Společnost"
                            required
                        ></v-text-field>

                      </v-col>

                      <v-col
                          cols="12"
                          md="4"
                      >
                        <v-text-field
                            v-model="workspaceInfo.contactInfo.address"
                            label="Adresa"
                        ></v-text-field>
                      </v-col>

                      <v-col
                          cols="12"
                          md="4"
                      >
                        <v-text-field
                            ref="ico"
                            v-model="workspaceInfo.contactInfo.ico"
                            label="IČO"
                        ></v-text-field>
                      </v-col>

                      <v-col
                          cols="12"
                          md="4"
                      >
                        <v-text-field
                            v-model="workspaceInfo.contactInfo.dic"
                            label="DIČ"
                        ></v-text-field>
                      </v-col>

                      <v-col
                          cols="12"
                          md="4"
                      >
                        <v-text-field
                            v-model="workspaceInfo.contactInfo.bank_account"
                            label="Bankovní účet"
                        ></v-text-field>
                      </v-col>

                      <v-col
                          cols="12"
                          md="4"
                      >
                        <v-text-field
                            v-model="workspaceInfo.contactInfo.hour_rate"
                            :rules="[numberRule]"
                            label="Hodinová sazba"
                        ></v-text-field>
                      </v-col>

                      <v-col
                          cols="12"
                          md="4"
                      >
                        <v-select
                            :items="platceDPH"
                            :item-text="'name'"
                            :item-value="'value'"
                            v-model="workspaceInfo.contactInfo.platce_DPH"
                            name="platceDPH"
                            label="Jsem plátce DPH:"
                        ></v-select>
                      </v-col>

                    </v-row>
                    <v-card-actions class="justify-end">
                      <v-btn
                          style="z-index: 0"
                          color="primary"
                          class="mr-4"
                          @click="updateWorkspaceInfo()"
                      >
                        Aktualizovat
                      </v-btn>
                    </v-card-actions>
                  </v-container>
                </v-form>

              </v-col>
              <v-col
                  v-if="workspaceAdmin"
                  cols="12"
                  class="py-2 text-center"
              >
                <h3 style="text-align: left">Uživatelé ve workspace</h3>
                <div class="usersCont">
                  <span v-for="user in users.data" :key="user.id"
                        class="userBadge"
                  >
                    {{ user.name }}
                    <v-btn
                        icon
                        @click="deleteUser(user.id)"
                    >
                      <v-icon size="20" right color="red" left>mdi-close-thick</v-icon>
                    </v-btn>
                  </span>

                </div>
                <v-combobox
                    style="display: block"
                    v-model="newUserEmail"
                    label="Zadejte e-mail uživatele a stiskněte enter"
                    type="email"
                    multiple
                    chips
                ></v-combobox>
                <v-btn
                    style="float: left; z-index: 0;"
                  @click="addUserToWorkspace(newUserEmail)"
                >
                  Přidat uživatele
                </v-btn>

              </v-col>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                  v-if="workspaceAdmin"
                  color="error"
                  style="color: #ffffff"
                  @click="deleteWorkspace"
              >Smazat workspace</v-btn>
              <v-btn
                  color="primary"
                  outlined
                  @click="dialog.value = false"
              >Zavřít</v-btn>
            </v-card-actions>

            <v-snackbar
                v-model="snackbarDeleteMyself"
            >
              Nelze smazat sám sebe!

              <template v-slot:action="{ attrs }">
                <v-btn
                    color="pink"
                    text
                    v-bind="attrs"
                    @click="snackbarDeleteMyself = false"
                >
                  Zavřít
                </v-btn>
              </template>
            </v-snackbar>
            <v-snackbar
                v-model="snackbarDeleteWorkspace"
            >
              Workspace úspěšně smazán!

              <template v-slot:action="{ attrs }">
                <v-btn
                    color="pink"
                    text
                    v-bind="attrs"
                    @click="snackbarDeleteWorkspace = false"
                >
                  Zavřít
                </v-btn>
              </template>
            </v-snackbar>

          </v-card>
        </template>
      </v-dialog>
    </div>

  </div>
</template>

<script>

import gql from "graphql-tag";
import axios from "axios";

const GET_WORKSPACES = gql`
  query getWorkspaces {
    workspaces {
      data {
        id
        name
      }
    }
  }
`;

const GET_USERS = gql`
  query getUsers {
    users {
      data {
        id
        name
      }
    }
  }
`;

export default {
name: "changeWorkspace",
  data: () => ({
    snackbarDeleteWorkspace: false,
    snackbarDeleteMyself: false,
    platceDPH: [
      { name: 'Ne', value: 0 },
      { name: 'Ano', value: 1 }
    ],
    selectedPlatceDPH: 0,
    newUserEmail: [],
    numberRule: v  => {
      if (!v.trim) return true;
      if (!isNaN(parseFloat(v)) && v >= 0 && v <= 99999) return true;
      return 'Hodnota musí být kladná!';
    },
    nameRules: [
      v => !!v || 'Jméno je požadováno!',
      v => v.length <= 40 || 'Jméno musí být menší než 40 znaků!',
    ],
    emailRules: [
      v => !!v || 'E-mail je požadován!',
      v => /.+@.+/.test(v) || 'E-mail musí být validní!',
    ],
    participans: [],
    members: [],
    client: null,
    dialog: false,
    workspaceInfo: null,
    workspaceName: {},
    workspaceAdmin: false,
    valid: true,
  }),
  apollo: {
    workspaces: {
      query: GET_WORKSPACES
    },
    users: {
      query: GET_USERS
    }
  },

  watch: {
    "$store.state.user.active_workspace_name": {
      handler: function(nv) {
        console.log("WATCH ACTIVE WORKSPACE NAME ")
        this.workspaceName = nv;
        console.log(this.workspaceName)
      },
      immediate: true // provides initial (not changed yet) state
    },
  },

  methods: {

    loadWorkspace() {
      if(this.getUser.adminWorkspaces.find( ({ workspace_id }) => workspace_id == this.getUser.active_workspace_id )) {
        this.workspaceAdmin = true;
      } else  {
        this.workspaceAdmin = false;
      }

      axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
        'workspaces(id:' + this.getUser.active_workspace_id + '){' +
          'data{' +
            'id,' +
            'name,' +
            'default,' +
            'contactInfo{' +
              'id,' +
              'address,' +
              'name,' +
              'bank_account,' +
              'company,' +
              'ico,' +
              'dic,' +
              'hour_rate,' +
              'platce_DPH,' +
            '}' +
          '}' +
        '}' +
        '}'
      ).then(response => {
        console.log("RESPONSE: ")
        console.log(response.data)
        this.workspaceInfo = response.data.data.workspaces.data[0];

        if (!this.workspaceInfo.contactInfo) {
          this.workspaceInfo.contactInfo = {}
        }

        if (!this.workspaceInfo.contactInfo.company || !this.workspaceInfo.contactInfo) {
          this.workspaceInfo.contactInfo.company = '';
        }
        if (!this.workspaceInfo.contactInfo.address || !this.workspaceInfo.contactInfo) {
          this.workspaceInfo.contactInfo.address = '';
        }
        if (!this.workspaceInfo.contactInfo.bank_account || !this.workspaceInfo.contactInfo) {
          this.workspaceInfo.contactInfo.bank_account = '';
        }
        if (!this.workspaceInfo.contactInfo.dic || !this.workspaceInfo.contactInfo) {
          this.workspaceInfo.contactInfo.dic = '';
        }
        if (!this.workspaceInfo.contactInfo.ico || !this.workspaceInfo.contactInfo) {
          this.workspaceInfo.contactInfo.ico = '';
        }
        if (!this.workspaceInfo.contactInfo.hour_rate || !this.workspaceInfo.contactInfo) {
          this.workspaceInfo.contactInfo.hour_rate = 0;
        }
        if (!this.workspaceInfo.contactInfo.vat_payer || !this.workspaceInfo.contactInfo) {
          this.workspaceInfo.contactInfo.vat_payer = 0;
        }

        //this.$refs.ProjectSettings.updateProjectData(this.project);
        //this.projectName = this.project.name;
      });

    },


    deleteWorkspace() {

      if (this.workspaceInfo.default === true) {
        alert("Původní workspace nelze smazat!")
      } else {
        this.$confirm(
            "Opravdu si přejete smazat tento workspace?",
            { color: "warning", title: "Upozornění", buttonTrueText: "Ano", buttonFalseText: "Ne", icon: "mdi-alert"  }
        ).then(res => {

          if (res) {
            this.$apollo.mutate({
              mutation: gql(`mutation {
            delete_workspace (id: ` + this.workspaceInfo.id + `) {
                id
              }
            }`
              ),
            }).then(() => {
              this.snackbarDeleteWorkspace = true;
              this.dialog = false;
              window.location.reload()
            });
          }
        });
      }
    },

    deleteUser(userId) {

      if (userId === this.getUser.id) {
        this.snackbarDeleteMyself = true;
      } else {

        this.$confirm(
            "Opravdu si přejete smazat uživatele ?",
            { color: "warning", title: "Upozornění", buttonTrueText: "Ano", buttonFalseText: "Ne", icon: "mdi-alert"  }
        ).then(res => {

          if (res) {
            this.$apollo.mutate({
              mutation: gql(`mutation {
            delete_user_workspace (user_id: ` + userId + `) {
                id
              }
            }`
              ),
              refetchQueries: () => [{ query: GET_USERS }]
            }).catch((err) => console.log(err))
          }
        });

      }
    },

    updateWorkspaceInfo() {

      if (this.$refs.form.validate()) {

        this.$apollo.mutate({
          mutation: gql(`mutation {
          update_workspace (
                name: "` + this.workspaceInfo.name + `",
                company: "` + this.workspaceInfo.contactInfo.company + `",
                address: "` + this.workspaceInfo.contactInfo.address + `",
                ICO: "` + this.workspaceInfo.contactInfo.ico + `",
                DIC: "` + this.workspaceInfo.contactInfo.dic + `",
                bank_account: "` + this.workspaceInfo.contactInfo.bank_account + `",
                hour_rate: ` + this.workspaceInfo.contactInfo.hour_rate + `,
                platce_DPH: ` + this.workspaceInfo.contactInfo.platce_DPH + `,
            ) {
              id
            }
          }`
          ),
        }).then(() => {
          this.workspaceName = this.workspaceInfo.name
          this.$store.commit("WORK");
          this.dialog = false
        });

      }

    },

    addUserToWorkspace(participans) {
      let newUsers = "";
      participans.forEach(
          user => newUsers += "\"" + user + "\","
      );

      console.log(newUsers);

      this.$apollo.mutate({
        mutation: gql(`mutation {
          update_workspace (users: [` + newUsers + `]) {
              id
            }
          }`
      ),
        refetchQueries: () => [{ query: GET_USERS }]
      }).then(() => {
          this.newUserEmail = [];
      });
    },

    changeWorkspace(workspaceId) {
      console.log("WORKSPACE ID: " + workspaceId);

      this.$apollo.mutate({
        mutation: gql(`mutation {
              update_user (active_workspace_id: ` + workspaceId + `) {
                id
                }
              }`
        )
      }).then((res) => {
        console.log(res)
        window.location.href = "/";
      }).catch((err) => console.log(err))

    },

    loadSelectorMenuItems() {
      // Users
      axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
          'users{' +
          'data{' +
          'id,' +
          'name,' +
          '}' +
          '}' +
          '}'
      ).then(response => {
        this.members = response.data.data.users;
        console.log("MEMBERS: " + this.members);
      });

    },

  },

  created () {
    this.$store.dispatch('getUser');
    this.loadSelectorMenuItems();
    this.loadWorkspace();
  },

  computed: {

    getUser() {
      return this.$store.state.user
    },

  },
}
</script>

<style>

  .userBadge {
    font-family: "AkkuratLL-Bold",sans-serif !important;
    display: inline-block;
    padding: 2px 15px 2px 15px;
    background-color: #1976d2;
    border-radius: 25px;
    color: #ffffff;
    font-weight: bold;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
  }

  .userBadge button {
    margin: 0;
    height: auto !important;
  }

  .usersCont {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
  }

  .changeW {
    max-width: 190px;
    margin-right: 10px;
    padding-left: 10px !important;
    padding-right: 1px !important;
  }

  .changeW .v-btn__content {
    width: 175px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block !important;
    font-size: 13px;
  }

</style>
