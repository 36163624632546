<template>
  <div>
    <div class="projectTitleAndSettings">
      <h1 class="viewTitle">{{ projectName }}</h1>
      <ProjectSettings :projectAdmin="projectAdmin" ref="ProjectSettings" v-bind:projectIdfromParent="projectId" class="iconSettings"></ProjectSettings>
    </div>

    <section id="poolCont">

      <div class="pool">
        <h3>Backlog</h3>
        <span class="numberOfTask"><b>({{ backlogTaskNumber }})</b></span>

        <div v-if="tasks">
          <draggable id="backlogDrag" :animation="200" :list="arrayBacklog" ghost-class="ghost-card" group="tasks" :move="moveTask">
          <v-card v-for="task in arrayBacklog" :key="task.id"
                  class="mx-auto projectTask"
                  max-width="344"
                  hover
          >
            <v-card-text v-if="task.status.name === 'Backlog'">

              <v-btn
                  style="min-width: auto; float: right; z-index: 0"
                  icon
                  @click="runTask(task.id)"
              >
                <v-icon size="40px">
                  mdi-play-circle
                </v-icon>
              </v-btn>

              <div
                style="display: inline-block; width: 85%"
                @click="() => showTaskDetailModal(task)"
              >
              <div v-if="task.project != null">
                <b style="display: inline-block; margin-right: 10px">{{ task.project.name }} </b>
                <div v-if="task.priority" style="display: inline-block">
                  <div v-if="task.priority.name === 'Low'" class="priorityLow">Nízká</div>
                  <div v-if="task.priority.name === 'Normal'" class="priorityMedium">Střední</div>
                  <div v-if="task.priority.name === 'High'" class="priorityHigh">Vysoká</div>
                </div>

              </div>
              <div v-else>
                <span style="display: inline-block; margin-right: 5px">Bez projektu</span>
                <div v-if="task.priority" style="display: inline-block">
                  <div v-if="task.priority.name === 'Low'" class="priorityLow">Nízká</div>
                  <div v-if="task.priority.name === 'Normal'" class="priorityMedium">Střední</div>
                  <div v-if="task.priority.name === 'High'" class="priorityHigh">Vysoká</div>
                </div>
              </div>

              <p class="title text--primary">
                {{ task.name }}
              </p>

              <p class="taskInfo"><v-icon class="mr-1">mdi-calendar-month</v-icon><span v-if="task.due_date != null"><b style="color: #000000">{{ task.due_date | moment("DD. MM. YYYY")}}</b></span><span v-else>Žádné datum</span></p>
              <p class="taskInfo"><v-icon class="mr-1">mdi-clock-time-eight-outline</v-icon><span><b style="color: #000000">{{ task.time_spend }}</b></span></p>
              <p class="taskInfo"><v-icon class="mr-1">mdi-currency-usd </v-icon><span v-if="task.time_estimate != null"><b style="color: #000000">{{ task.time_estimate }} hod.</b></span><span v-else><b style="color: #000000"> - hod.</b></span></p>
              <v-avatar
                  v-if="task.user != null"
                  color="primary"
                  size="26"
                  style="font-size: 12px"
              >
                {{ task.user.name.charAt(0) }}
              </v-avatar>

                <v-btn
                    style="min-width: auto; z-index: 0"
                    class="checkButton"
                    raised
                    depressed
                    icon
                    @click="checkTaskDone(task.id)"
                >
                  <v-icon size="25px">
                    mdi-check-circle-outline
                  </v-icon>
                </v-btn>

              </div>

            </v-card-text>

          </v-card>
          </draggable>
        </div>

      </div>
      <div class="pool">
        <h3>V procesu</h3>
        <span class="numberOfTask"><b>({{ inProgressTaskNumber }})</b></span>
        <div v-if="tasks">
          <draggable id="inProgressDrag" :animation="200" :list="arrayInProgress" ghost-class="ghost-card" group="tasks" :move="moveTask">
          <v-card v-for="task in arrayInProgress" :key="task.id"
                  class="mx-auto projectTask"
                  max-width="344"
                  hover
          >

            <v-card-text v-if="task.status.name === 'In Progress'">
              <v-btn
                  style="min-width: auto; float: right; z-index: 0"
                  icon
                  @click="runTask(task.id)"
              >
                <v-icon size="40px">
                  mdi-play-circle
                </v-icon>
              </v-btn>

              <div
                      style="display: inline-block; width: 85%"
                      @click="() => showTaskDetailModal(task)"
              >
              <div v-if="task.project != null">
                <b style="display: inline-block; margin-right: 10px">{{ task.project.name }} </b>
                <div v-if="task.priority" style="display: inline-block">
                  <div v-if="task.priority.name === 'Low'" class="priorityLow">Nízká</div>
                  <div v-if="task.priority.name === 'Normal'" class="priorityMedium">Střední</div>
                  <div v-if="task.priority.name === 'High'" class="priorityHigh">Vysoká</div>
                </div>

              </div>
              <div v-else>
                <span style="display: inline-block; margin-right: 5px">Bez projektu</span>
                <div v-if="task.priority" style="display: inline-block">
                  <div v-if="task.priority.name === 'Low'" class="priorityLow">Nízká</div>
                  <div v-if="task.priority.name === 'Normal'" class="priorityMedium">Střední</div>
                  <div v-if="task.priority.name === 'High'" class="priorityHigh">Vysoká</div>
                </div>
              </div>
              <p class="title text--primary">
                {{ task.name }}
              </p>

              <p class="taskInfo"><v-icon class="mr-1">mdi-calendar-month</v-icon><span v-if="task.due_date != null"><b style="color: #000000">{{ task.due_date | moment("DD. MM. YYYY")}}</b></span><span v-else>Žádný datum</span></p>
              <p class="taskInfo"><v-icon class="mr-1">mdi-clock-time-eight-outline</v-icon><span><b style="color: #000000">{{ task.time_spend }}</b></span></p>
              <p class="taskInfo"><v-icon class="mr-1">mdi-currency-usd </v-icon><span v-if="task.time_estimate != null"><b style="color: #000000">{{ task.time_estimate }} hod.</b></span><span v-else><b style="color: #000000"> - hod.</b></span></p>
              <v-avatar
                  v-if="task.user != null"
                  color="primary"
                  size="26"
                  style="font-size: 12px"
              >
                {{ task.user.name.charAt(0) }}
              </v-avatar>
                <v-btn
                    style="min-width: auto; z-index: 0"
                    class="checkButton"
                    raised
                    depressed
                    icon
                    @click="checkTaskDone(task.id)"
                >
                  <v-icon size="25px">
                    mdi-check-circle-outline
                  </v-icon>
                </v-btn>
              </div>

            </v-card-text>

          </v-card>
          </draggable>
        </div>

      </div>
      <div class="pool">
        <h3>Dokončeno</h3>
        <span class="numberOfTask"><b>({{ doneTaskNumber }})</b></span>

        <div v-if="tasks">
          <draggable id="doneDrag" :animation="200" :list="arrayDone" ghost-class="ghost-card" group="tasks" :move="moveTask">
          <v-card v-for="task in arrayDone" :key="task.id"
                  class="mx-auto projectTask"
                  max-width="344"
                  hover
                  @click="() => showTaskDetailModal(task)"
          >
            <v-card-text v-if="task.status.name === 'Done'">
              <div v-if="task.project != null">
                <b style="display: inline-block; margin-right: 10px">{{ task.project.name }} </b>
                <div v-if="task.priority" style="display: inline-block">
                  <div v-if="task.priority.name === 'Low'" class="priorityLow">Nízká</div>
                  <div v-if="task.priority.name === 'Normal'" class="priorityMedium">Střední</div>
                  <div v-if="task.priority.name === 'High'" class="priorityHigh">Vysoká</div>
                </div>

              </div>
              <div v-else>
                <span style="display: inline-block; margin-right: 5px">Bez projektu</span>
                <div v-if="task.priority" style="display: inline-block">
                  <div v-if="task.priority.name === 'Low'" class="priorityLow">Nízká</div>
                  <div v-if="task.priority.name === 'Normal'" class="priorityMedium">Střední</div>
                  <div v-if="task.priority.name === 'High'" class="priorityHigh">Vysoká</div>
                </div>
              </div>
              <p class="title text--primary">
                {{ task.name }}
              </p>

              <p class="taskInfo"><v-icon class="mr-1">mdi-calendar-month</v-icon><span v-if="task.due_date != null"><b style="color: #000000">{{ task.due_date | moment("DD. MM. YYYY")}}</b></span><span v-else>Žádný datum</span></p>
              <p class="taskInfo"><v-icon class="mr-1">mdi-clock-time-eight-outline</v-icon><span><b style="color: #000000">{{ task.time_spend }}</b></span></p>
              <p class="taskInfo"><v-icon class="mr-1">mdi-currency-usd </v-icon><span v-if="task.time_estimate != null"><b style="color: #000000">{{ task.time_estimate }} hod.</b></span><span v-else><b style="color: #000000"> - hod.</b></span></p>
              <v-avatar
                  v-if="task.user != null"
                  color="primary"
                  size="26"
                  style="font-size: 12px"
              >
                {{ task.user.name.charAt(0) }}
              </v-avatar>

            </v-card-text>

          </v-card>
          </draggable>
        </div>

      </div>
      <div class="pool">
        <h3>Schváleno klientem</h3>
        <span class="numberOfTask"><b>({{ approvedTaskNumber }})</b></span>

        <div v-if="tasks">
          <draggable id="approvedDrag" :animation="200" :list="arrayApproved" ghost-class="ghost-card" group="tasks" :move="moveTask">
          <v-card v-for="task in arrayApproved" :key="task.id"
                  class="mx-auto projectTask"
                  max-width="344"
                  hover
                  @click="() => showTaskDetailModal(task)"
          >

            <v-card-text v-if="task.client_approved === true ">
              <div v-if="task.project != null">
                <b style="display: inline-block; margin-right: 10px">{{ task.project.name }} </b>
                <div v-if="task.priority" style="display: inline-block">
                  <div v-if="task.priority.name === 'Low'" class="priorityLow">Nízká</div>
                  <div v-if="task.priority.name === 'Normal'" class="priorityMedium">Střední</div>
                  <div v-if="task.priority.name === 'High'" class="priorityHigh">Vysoká</div>
                </div>

              </div>
              <div v-else>
                <span style="display: inline-block; margin-right: 5px">Bez projektu</span>
                <div v-if="task.priority" style="display: inline-block">
                  <div v-if="task.priority.name === 'Low'" class="priorityLow">Nízká</div>
                  <div v-if="task.priority.name === 'Normal'" class="priorityMedium">Střední</div>
                  <div v-if="task.priority.name === 'High'" class="priorityHigh">Vysoká</div>
                </div>
              </div>
              <p class="title text--primary">
                {{ task.name }}
              </p>

              <p class="taskInfo"><v-icon class="mr-1">mdi-calendar-month</v-icon><span v-if="task.due_date != null"><b style="color: #000000">{{ task.due_date | moment("DD. MM. YYYY")}}</b></span><span v-else>Žádný datum</span></p>
              <p class="taskInfo"><v-icon class="mr-1">mdi-clock-time-eight-outline</v-icon><span><b style="color: #000000">{{ task.time_spend }}</b></span></p>
              <p class="taskInfo"><v-icon class="mr-1">mdi-currency-usd </v-icon><span v-if="task.time_estimate != null"><b style="color: #000000">{{ task.time_estimate }} hod.</b></span><span v-else><b style="color: #000000"> - hod.</b></span></p>
              <v-avatar
                  v-if="task.user != null"
                  color="primary"
                  size="26"
                  style="font-size: 12px"
              >
                {{ task.user.name.charAt(0) }}
              </v-avatar>
            </v-card-text>

          </v-card>
          </draggable>
        </div>

      </div>
      <div class="pool">
        <h3>Vyfakturováno</h3>
        <span class="numberOfTask"><b>({{ invoicedTaskNumber }})</b></span>

        <div v-if="tasks">

          <v-card v-for="task in arrayInvoiced" :key="task.id"
                  class="mx-auto projectTask"
                  max-width="344"
                  hover
                  @click="() => showTaskDetailModal(task)"
          >
            <v-card-text v-if="task.invoice !== null">
              <div v-if="task.project != null">
                <b style="display: inline-block; margin-right: 10px">{{ task.project.name }} </b>
                <div v-if="task.priority" style="display: inline-block">
                  <div v-if="task.priority.name === 'Low'" class="priorityLow">Nízká</div>
                  <div v-if="task.priority.name === 'Normal'" class="priorityMedium">Střední</div>
                  <div v-if="task.priority.name === 'High'" class="priorityHigh">Vysoká</div>
                </div>

              </div>
              <div v-else>
                <span style="display: inline-block; margin-right: 5px">Bez projektu</span>
                <div v-if="task.priority" style="display: inline-block">
                  <div v-if="task.priority.name === 'Low'" class="priorityLow">Nízká</div>
                  <div v-if="task.priority.name === 'Normal'" class="priorityMedium">Střední</div>
                  <div v-if="task.priority.name === 'High'" class="priorityHigh">Vysoká</div>
                </div>
              </div>
              <p class="title text--primary">
                {{ task.name }}
              </p>

              <p class="taskInfo"><v-icon class="mr-1">mdi-calendar-month</v-icon><span v-if="task.due_date != null"><b style="color: #000000">{{ task.due_date | moment("DD. MM. YYYY")}}</b></span><span v-else>Žádný datum</span></p>
              <p class="taskInfo"><v-icon class="mr-1">mdi-clock-time-eight-outline</v-icon><span><b style="color: #000000">{{ task.time_spend }}</b></span></p>
              <p class="taskInfo"><v-icon class="mr-1">mdi-currency-usd </v-icon><span v-if="task.time_estimate != null"><b style="color: #000000">{{ task.time_estimate }} hod.</b></span><span v-else><b style="color: #000000"> - hod.</b></span></p>
              <v-avatar
                  v-if="task.user != null"
                  color="primary"
                  size="26"
                  style="font-size: 12px"
              >
                {{ task.user.name.charAt(0) }}
              </v-avatar>

            </v-card-text>

          </v-card>
        </div>

      </div>
    </section>

    <TaskDetail ref="TaskDetail" @closeDialogChild="onCloseDialog" :openDialog="dialog" :taskInfo="task_info"></TaskDetail>

  </div>
</template>

<script>
import ProjectSettings from "@/components/ProjectSettings";
import TaskDetail from "@/components/TaskDetail";
import gql from "graphql-tag";
import axios from "axios";
import draggable from 'vuedraggable'

export default {
name: "Project",
  components: {TaskDetail, ProjectSettings, draggable},

  data() {
    return {
      testBoolean: false,
      task_info: {},
      dialog: false,
      project: null,
      projectAdmin: false,
      projectName: "",
      tasks: [],
      projectId: null,
      backlogTaskNumber: 0,
      inProgressTaskNumber: 0,
      doneTaskNumber: 0,
      approvedTaskNumber: 0,
      invoicedTaskNumber: 0,
      arrayBacklog: [],
      arrayInProgress: [],
      arrayDone: [],
      arrayApproved: [],
      arrayInvoiced: [],
    }
  },

  watch: {
      '$route.params.projectID': function () {
          this.loadProject();
      },

    "$store.state.projects": {
      handler: function(nv) {
        console.log("WATCH PROJECT")
        this.myprop = nv;
        this.loadProject();
      },
      immediate: true // provides initial (not changed yet) state
    },
  },

  created() {
    this.loadProject();
  },

  computed: {
    getUser() {
      return this.$store.state.user
    },
  },

  methods: {

    moveTask(evt) {
      let destinationArray = evt.to.id;
      let taskId = evt.draggedContext.element.id;
      //let taskApproved = evt.draggedContext.element.client_approved
      let actualStatusId = evt.draggedContext.element.status.id;
      let statusId = 1;
      let taskApprovedAfter = false;

      if (destinationArray === "backlogDrag") {
          statusId = 1;
          console.log("Moved to BACKLOG: " + taskId)
      } else if (destinationArray === "inProgressDrag") {
        statusId = 2;
        console.log("Moved to IN PROGRESS: " + taskId)
      } else if (destinationArray === "doneDrag") {
        statusId = 3;
        console.log("Moved to IN DONE: " + taskId)
      } else if (destinationArray === "approvedDrag") {
        statusId = actualStatusId;
        taskApprovedAfter = true;
        console.log("Moved to APPROVED: " + taskId)
      }

      this.$apollo.mutate({
        mutation: gql(`mutation {
          update_task (
            id: ` + taskId + `,
            status_id: ` + statusId + `,
            client_approved: ` + taskApprovedAfter + `,
          ) {
            id
          }
        }`
        )
      }).then(() => {
        this.$store.commit("TASKS");
        this.$store.commit("TOPNAV");
        this.$store.commit("PROJ");
      });

      console.log("TAGET ARRAY: ");
      console.log(taskId)

    },

    checkTaskDone(taskId) {
      this.$apollo.mutate({
        mutation: gql(`mutation {
          update_task (
            id: ` + taskId + `,
            status_id: 3,
          ) {
            id
          }
          }`
          )
      }).then(() => {
        this.snackbarDone = true;
        this.$store.commit("TASKS");
        this.$store.commit("TOPNAV");
        this.$store.commit("PROJ");
      });
    },

    showTaskDetailModal(taskInfoData) {
        this.$refs.TaskDetail.runDialog(taskInfoData);
    },

    onCloseDialog() {
      this.dialog = false;
    },

    loadProject() {
      this.projectId = this.$route.params.projectID;

      axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
        'projects(id:' + this.projectId + '){' +
          'data{' +
            'id,' +
            'name,' +
            'active,' +
            'userProjects{' +
              'user{' +
                'id,' +
                'name' +
              '}' +
            '}' +
            'client{' +
              'id,' +
              'contactInfo{' +
                'company' +
              '}' +
            '}' +
          '}' +
        '}' +
        '}'
      ).then(response => {
          this.project = response.data.data.projects.data[0];
          this.$refs.ProjectSettings.updateProjectData(this.project);
          this.projectName = this.project.name;
         if(this.getUser.adminProjects.find( ({ project_id }) => project_id == this.project.id)) {
             this.projectAdmin = true;
         } else  {
             this.projectAdmin = false;
         }
         console.log("Project admin");
         console.log(this.projectAdmin);
      });

      axios.get('https://dev.api.project.devg.cz/graphql/query?query=query{' +
        'tasks(project_id:' + this.projectId + '){' +
          'data{' +
            'id,' +
            'name,' +
            'description,' +
            'time_estimate,' +
            'due_date,' +
            'client_approved,' +
            'time_spend,' +
            'attachments {' +
              'id,' +
              'name,' +
              'extension' +
            '}' +
            'invoice{' +
              'id' +
            '}' +
            'project{' +
              'id,' +
              'name' +
            '}' +
            'user{' +
              'id,' +
              'name' +
            '}' +
            'priority{' +
              'id,' +
              'name' +
            '}' +
            'status{' +
              'id,' +
              'name' +
            '}' +
              'timeRecords{' +
              'id,' +
              'start,' +
              'end' +
            '}' +
          '}' +
        '}' +
        '}'
      ).then(response => {
        console.log("Response:");
        console.log(response);
        this.tasks = response.data.data.tasks;
        for(let i = 0; i < this.tasks.data.length; i++) {
            this.tasks.data[i].time_spend = this.formatTime(this.tasks.data[i].time_spend);
        }

        this.backlogTaskNumber = 0;
        this.inProgressTaskNumber = 0;
        this.doneTaskNumber = 0;
        this.approvedTaskNumber = 0;
        this.invoicedTaskNumber = 0;

        this.arrayBacklog = [];
        this.arrayInProgress = [];
        this.arrayDone = [];
        this.arrayApproved = [];
        this.arrayInvoiced = [];

        console.log("TASKS LENGTH: " + this.tasks.data.length);
        console.log(this.tasks.data);
        for (let i = 0; i < this.tasks.data.length; i++) {
          if (this.tasks.data[i].status.name === "Backlog") {
            this.backlogTaskNumber++;
            this.arrayBacklog.push(this.tasks.data[i])
          } else if (this.tasks.data[i].status.name === "In Progress") {
            this.inProgressTaskNumber++;
            this.arrayInProgress.push(this.tasks.data[i])

          } else if (this.tasks.data[i].status.name === "Done") {

            if (this.tasks.data[i].status.name === "Done" && this.tasks.data[i].client_approved === false && this.tasks.data[i].invoice === null) {
              this.doneTaskNumber++;
              this.arrayDone.push(this.tasks.data[i])
            }

            if (this.tasks.data[i].client_approved === true && this.tasks.data[i].invoice === null) {
              this.approvedTaskNumber++;
              this.arrayApproved.push(this.tasks.data[i])
            }

            if (this.tasks.data[i].client_approved === true && this.tasks.data[i].invoice !== null) {
              this.invoicedTaskNumber++;
              this.arrayInvoiced.push(this.tasks.data[i])
            }

          }
        }

        console.log("Backlog");
        console.log(this.arrayBacklog);
        console.log("In Progess");
        console.log(this.arrayInProgress);
        console.log("Done");
        console.log(this.arrayDone);
        console.log("Client Approved");
        console.log(this.arrayApproved);
        console.log("Invoiced");
        console.log(this.arrayInvoiced);


      });

    },

    runTask(taskId)  {
      this.$apollo.mutate({
        mutation: gql(`mutation {
          time_record_action (task_id: ` + taskId + `) {
            id
            }
          }`
        ),
      }).then(() => {
          this.$store.commit("TOPNAV");
          this.$store.commit("TASKS");
          this.$store.commit("PROJ");
          this.getLastTasks();
      });
    },

    formatTime(intVal) {
        let hours   = Math.floor(intVal / 3600);
        let minutes = Math.floor((intVal - (hours * 3600)) / 60);
        let seconds = intVal - (hours * 3600) - (minutes * 60);

        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        return hours+':'+minutes+':'+seconds;
    }

  }
}

</script>

<style>

  #poolCont {
    display: flex;
    flex-direction: row;
    overflow: scroll;
  }

  .pool {
    flex-grow: 1;
    border-right: 1px solid;
    min-height: 100vh;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 300px;
    max-width: 300px;
    margin-bottom: 25px;
  }

  .pool h3 {
    font-family: "AkkuratLL-Bold",sans-serif !important;
    text-align: left;
    margin-bottom: 10px;
    display: inline-block;
  }

  .numberOfTask {
    display: inline-block;
    margin-left: 10px;
    color: rgba(0, 0, 0, 0.6);
    float: right;
  }

  .projectTitleAndSettings .iconSettings, .projectTitleAndSettings h1 {
    display: inline-block;
    margin-right: 20px;
  }

  .projectTask {
    margin-bottom: 10px;
  }

  .projectTask .title {
    line-height: 18px;
    margin-top: 5px;
    font-size: 18px !important;
  }

  .taskInfo {
    margin-bottom: 5px !important;
  }

  .projectTask .v-avatar {
    color: #ffffff;
  }

  .priorityLow {
    font-family: "AkkuratLL-Bold",sans-serif !important;
    border-radius: 30px;
    background-color: #3366ff;
    display: inline-block;
    color: #ffffff;
    padding: 0px 15px 0px 15px;
    font-weight: bold;
    font-size: 10px;
  }

  .priorityMedium {
    font-family: "AkkuratLL-Bold",sans-serif !important;
    border-radius: 30px;
    background-color: #ff6600;
    display: inline-block;
    color: #ffffff;
    padding: 0px 15px 0px 15px;
    font-weight: bold;
    font-size: 10px;
  }

  .priorityHigh {
    font-family: "AkkuratLL-Bold",sans-serif !important;
    border-radius: 30px;
    background-color: #ff0000;
    display: inline-block;
    color: #ffffff;
    padding: 0px 15px 0px 15px;
    font-weight: bold;
    font-size: 10px;
  }

  .projectTask:nth-child(n):empty {
    display: none;
  }


  .checkButton {
    position: absolute;
    z-index: 0;
    bottom: -3px;
    right: 15px;
  }

  .checkButton:hover {
    color: #00bf9c !important;
  }

  .viewTitle {
    font-family: "AkkuratLL-Bold",sans-serif !important;
    margin-top: 1%;
    margin-bottom: 1%;
    display: inline-block;
    flex: auto;
    font-size: 22px;
    margin-left: 10px;
  }

  .ghost-card {
    opacity: 0.5;
    background: #F7FAFC;
    border: 1px solid #4299e1 !important;
  }

  #backlogDrag, #inProgressDrag, #doneDrag, #approvedDrag {
    height: 100vh;
  }

</style>
