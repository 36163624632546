<template>
  <div>
    <v-app v-if="getUser.nickname === undefined">
      <v-main>
        <v-container fluid>
          <v-flex xs12 class="text-xs-center" mt-5>
            <v-list-item class="AppName" style="text-align: center; margin-bottom: 30px">
              <v-list-item-content>
                <router-link to="/" class="title color-blue">
                  Pro Track
                </router-link>
                <v-list-item-subtitle>
                  Project Management Tool
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-flex>
          <router-view></router-view>
        </v-container>
      </v-main>
      <BottomNavgiation></BottomNavgiation>
    </v-app>

    <v-app v-else>
      <v-navigation-drawer app style="max-height: calc(100% - 60px) !important;">
        <!-- -->
        <LeftNavigation></LeftNavigation>
      </v-navigation-drawer>

      <v-app-bar app>
        <!-- -->
        <TopNavigation></TopNavigation>
      </v-app-bar>

      <!-- Sizes your content based upon application components -->
      <v-main>
        <!-- Provides the application the proper gutter -->
        <v-container fluid>
          <!-- If using vue-router -->
          <router-view></router-view>
        </v-container>
      </v-main>
      <!-- -->
      <BottomNavgiation></BottomNavgiation>
    </v-app>

  </div>
</template>

<script>
import LeftNavigation from "@/components/LeftNavigation";
import TopNavigation from "@/components/TopNavigation";
import BottomNavgiation from "@/components/BottomNavgiation";
export default {
  name: "App",

  components: {
    BottomNavgiation,
    TopNavigation,
    LeftNavigation
  },

  data: () => ({
    isAuthenticated: true,
  }),

  computed: {
    getUser() {

      return this.$store.state.user
    },

  },

  created() {
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    let status = params.get("status");
    if(status && status === "loginSuccess") {
      alert("Úspěšně přihlášen");
    } else if(status && status === "alreadyExists") {
      alert("Email je již zaregistrován!")
    } else if(status && status === "error") {
      alert("Nastala neočekávaná chyba, prosím zkuste znovu!")
    } else if (status && status === "wrongCredentials") {
      alert("Špatné přihlašovací údaje.")
    }

    this.$store.dispatch('getUser');

    console.log("TADY: " + this.getUser.name)


  },

};

</script>

<style>
  @font-face {
    font-family: "AkkuratLL-Regular";
    src: local("AkkuratLL-Regular"),
    url(./assets/fonts/AkkuratLL-Regular.ttf) format('truetype'),
    url(./assets/fonts/AkkuratLL-Regular.woff) format('woff'),
    url(./assets/fonts/AkkuratLL-Regular.woff2) format('woff2');
  }

  @font-face {
    font-family: "AkkuratLL-Bold";
    src: local("AkkuratLL-Bold"),
    url(./assets/fonts/AkkuratLL-Bold.woff) format('woff'),
    url(./assets/fonts/AkkuratLL-Bold.woff2) format('woff2');
  }

  @font-face {
    font-family: "AkkuratLL-Light";
    src: local("AkkuratLL-Light"),
    url(./assets/fonts/AkkuratLL-Light.woff) format('woff'),
    url(./assets/fonts/AkkuratLL-Light.woff2) format('woff2');
  }

  *, body, span, p {
    font-family: "AkkuratLL-Regular",sans-serif;
  }

  b, h1, h2 {
    font-family: "AkkuratLL-Bold",sans-serif !important;
  }

  .color-blue {
    color: #3D70B2 !important;
  }

  .color-red {
    color: #FF5666 !important;
  }

  .color-green {
    color: #41D6C3 !important;
  }

  .color-orange {
    color: #fd7b38 !important;
  }

  a {
    text-decoration: unset !important;
  }

  /* MAIN STYLES - NEED TO CHANGE AS THEME VARIABLES */
  .v-application {
    background-color: #f7f6f4 !important;
  }

  .v-navigation-drawer {
    margin: 30px;
    border-radius: 14px;
    width: auto !important;
  }

  .v-main {
    margin-left: 57px;
    margin-top: 30px;
    padding-right: 28px !important;
  }

  .v-sheet.v-app-bar.v-toolbar {
    margin: 30px 30px 30px 60px !important;
    border-radius: 14px !important;
  }

  .container {
    padding: 0;
  }

  .v-autocomplete {
    display: inline-block;
  }

  .v-list--nav {
    padding: 0;
  }

  .titleAndBtn {
    display: flex;
    flex-direction: row;
    padding-top: 15px;
  }

  .v-snack__action > button {
    margin-bottom: 0 !important;
  }

  .v-dialog:not(.v-dialog--fullscreen) {
    background-color: #ffffff !important;
  }

  .v-menu__content {
    z-index: 99999 !important;
  }

  .v-select.v-select--chips .v-select__selections {
    min-height: auto !important;
  }

  @media print {

    .metaInvoice {
      display: inline-block !important;
      width: 33% !important;
    }

    .section-spacer {
      display: inline-block !important;
      width: 50% !important;
    }

    .inovoiceSelectors, .viewTitle {
      display: none !important;
    }

    .v-main {
      width: 100% !important;
      padding: 0 !important;
    }

    .viewTitle {
      display: none;
    }

    button {
      display: none;
    }

    .v-navigation-drawer__content, .theme--light.v-navigation-drawer, header {
      display: none !important;
    }

    select {
      appearance: none;
    }

    input, select, textarea {
      background-color: transparent;
    }

    #invoice-app {
      width: 100%;
    }

    .main-content {
      width: 100%;
      align-items: flex-start;
      padding: 0 !important;
    }
  }

  @media only screen and (max-width: 900px) {

    header {
      height: 110px !important;
    }

    .v-main {
      padding-top: 110px !important;
    }

  }

</style>
